import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';

// Icons
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// Custom components
import { Spinner } from '../../../';

// Utils
import messages from '../../../../static/messages';

// Services
import warrantyServices from '../../../../services/warrantyServices';

// Styles
import { proposal_grouped_items } from '../../../../static/styles';

// Dragging

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => <DragIndicatorIcon sx={{ cursor: 'grab' }} />); 
const SortableItem = SortableElement(({children}) => <div>{children}</div>);
const SortContainer = SortableContainer(({children}) => <div>{children}</div>);

const WarrantyModule = ({ systemName, data, setSystems, system }) => {
  const [allWarranties, setAllWarranties] = useState([]);
  const [warrantiesList, setWarrantiesList] = useState([]);
  const [filtersData, setFiltersData] = useState();
  const [yearsList, setYearsList] = useState([]);
  const [currentItem, setCurrentItem] = useState();
  const [currentGroupItem, setCurrentGroupItem] = useState();

  const confirm = useConfirm();

  // Action functions
  const onAddNewItem = (event) => {
    const val = event.target.value;
    const selected_item = allWarranties.find((el) => el.years === val && el.description === currentItem);

    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          warranties: system.warranties.concat({
            flexibleItems: [
              {
                item: selected_item,
                price: null,
              },
            ],
            selectedItemIndex: 0,
          }),
        }
      )
    );
    setCurrentItem();
  };

  const onAddNewItemToGroup = (event, warrantyID) => {
    const val = event.target.value;
    const selected_item = allWarranties.find((el) => el.years === val && el.description === currentGroupItem);

    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          warranties: warrantiesList.map((el) =>
            el.warrantyId !== warrantyID ? el : {
              ...el,
              flexibleItems: el.flexibleItems.concat({
                item: selected_item,
                price: null,
                itemId: el.flexibleItems.length,
              }),
            }
          ),
        }
      )
    );
    setCurrentGroupItem();
  };

  const onRemoveItem = (item) => {
    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          warranties: warrantiesList
            .map((el) =>
              !el.flexibleItems.includes(item) ? el : {
                ...el,
                flexibleItems: el.flexibleItems.filter((i) => i !== item),
              }
            )
            .filter((el) => el.flexibleItems.length),
        }
      )
    );
  };

  const onSelectItem = (event) => {
    const title = event.target.value;

    let unique_values = allWarranties
      .filter((el) => el.description === title)
      .reduce(
        (acc, el) => {
          if (acc.map[el.years]) return acc;

          acc.map[el.years] = true;
          acc.years.push(el.years);

          return acc;
        },
        {
          map: {},
          years: [],
        },
      );

    setCurrentItem(title);
    setYearsList(unique_values.years);
  };

  const onSelectGroupItem = (event) => {
    const title = event.target.value;

    let unique_values = allWarranties
      .filter((el) => el.description === title)
      .reduce(
        (acc, el) => {
          if (acc.map[el.years]) return acc;

          acc.map[el.years] = true;
          acc.years.push(el.years);

          return acc;
        },
        {
          map: {},
          years: [],
        },
      );

    setCurrentGroupItem(title);
    setYearsList(unique_values.years);
  };

  // Handler functions
  const handleChange = (event, warrantyID, itemID) => {
    const key = event.target.name;
    const val = event.target.value;

    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          warranties: warrantiesList.map((el) =>
            el.warrantyId !== warrantyID ? el : {
              ...el,
              ...(key === 'selectedItemIndex'
                ? { selectedItemIndex: Number(val) }
                : {
                    flexibleItems: el.flexibleItems
                      .map((item) =>
                        item.itemId !== itemID ? item : { ...item, [key]: Number(val) }
                      )
                      .sort((a, b) => a.itemId - b.itemId),
                  }
              ),
            }
          ),
        }
      )
    );
  };

  const handleChangeToOther = (event, warrantyID, itemID) => {
    const key = event.target.name;
    const val = event.target.value;

    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          warranties: warrantiesList.map((el) =>
            el.warrantyId !== warrantyID || key === 'selectedItemIndex' ? el : {
              ...el,
              flexibleItems: el.flexibleItems
                .map((item) => item.itemId !== itemID ? item : { ...item, [key]: val }),
            }
          ),
        }
      )
    );
  };

  const handleDelete = (item) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('item'),
    })
      .then(() => onRemoveItem(item))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleGroupItems = (item) => {
    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          warranties: warrantiesList
            .map((el) => el !== item ? el : { ...el, grouped: !el.grouped }),
        }
      )
    );
  };

  // Async functions
  const getWarrantiesList = async () => {
    try {
      const response = await warrantyServices.getAllWarranty(0, 1000000, true);

      if (response.status === 206) {
        let unique_values = response.data.data.reduce(
          (acc, el) => {
            if (acc.map[el.description]) return acc;

            acc.map[el.description] = true;
            acc.warranties.push({
              id: el.id,
              name: el.description,
            });

            return acc;
          },
          {
            map: {},
            warranties: [],
          },
        );

        setFiltersData(unique_values.warranties);
        setAllWarranties(response.data.data);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getWarrantiesList();
    if (!data) return;

    const listOfWarranties = data.map((el, warrantyId) => ({
      ...el,
      warrantyId,
      grouped: !!(el.grouped || el.flexibleItems.length > 1),
      flexibleItems: el.flexibleItems.map((item, itemId) => ({ ...item, itemId })),
    }));

    setWarrantiesList(listOfWarranties);
  }, [data]);

  if (!filtersData) return <Spinner />;

  return (
    <Box>
      <Typography variant="body1">
        <strong>Warranty</strong>
      </Typography>

      <SortContainer
        useDragHandle
        onSortEnd={({oldIndex, newIndex}) => {
          system.warranties = arrayMoveImmutable(warrantiesList, oldIndex, newIndex);
          setWarrantiesList(system.warranties);
        }}
      >
        {warrantiesList.map((el, index) => (
          <SortableItem key={el.warrantyId} index={index}>
            <Box sx={{ mt: 2 }}>
              <Grid container columnSpacing={1}>
                {warrantiesList.length > 1 && (
                  <Grid item sx={{ maxWidth: 42, pt: '8px !important', pl: '0 !important' }}>
                    <DragHandle />
                  </Grid>
                )}
                <Grid item xs={11} sx={{ ...(el.grouped && proposal_grouped_items)}}>
                  <RadioGroup value={el.selectedItemIndex}>
                    <SortContainer
                      useDragHandle
                      onSortEnd={({oldIndex, newIndex}) => {
                        system.warranties = warrantiesList.map(warranty => {
                          if (warranty !== el) return warranty;
                          const selectedItem = warranty.flexibleItems[el.selectedItemIndex];
                          const flexibleItems = arrayMoveImmutable(warranty.flexibleItems, oldIndex, newIndex);
                          return {
                            ...warranty,
                            selectedItemIndex: flexibleItems.indexOf(selectedItem),
                            flexibleItems
                          };
                        });
                        setWarrantiesList(system.warranties);
                      }}
                    >
                      {el.flexibleItems.map((item, index) => (
                        <SortableItem key={item.itemId} index={index}>
                          <Box
                            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                            className="item"
                          >
                            <Grid container columnSpacing={1} rowSpacing={2} sx={{ mb: 2 }}>
                              {el.grouped && el.flexibleItems.length > 1 && (
                                <Grid item sx={{ maxWidth: 42, pt: '24px !important' }}>
                                  <DragHandle />
                                </Grid>
                              )}
                              {el.grouped && el.flexibleItems.length > 1 && (
                                <Grid item sx={{ maxWidth: 42, pl: '0 !important' }}>
                                  <Radio
                                    name="selectedItemIndex"
                                    value={index}
                                    checked={index === el.selectedItemIndex}
                                    onChange={(event) => handleChange(event, el.warrantyId, item.itemId)}
                                  />
                                </Grid>
                              )}

                              <Grid item xs={12} md={6}>
                                <TextField
                                  label="Description"
                                  name="description"
                                  value={item.description || item.description === '' ? item.description : item.item?.description}
                                  fullWidth
                                  size="small"
                                  onChange={(event) => handleChangeToOther(event, el.warrantyId, item.itemId)}
                                />
                              </Grid>

                              <Grid item xs={12} md={2}>
                                <TextField
                                  label="Years"
                                  name="years"
                                  value={item.years || item.years === '' ? item.years : item.item?.years}
                                  fullWidth
                                  size="small"
                                  onChange={(event) => handleChangeToOther(event, el.warrantyId, item.itemId)}
                                />
                              </Grid>

                              <Grid item xs={12} md={2}>
                                <FormControl fullWidth size="small">
                                  <InputLabel required htmlFor="price">
                                    Price
                                  </InputLabel>
                                  <OutlinedInput
                                    required
                                    id="price"
                                    name="price"
                                    value={(item.price !== null ? item.price : item.item.price) || ''}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Price"
                                    onChange={(event) => handleChange(event, el.warrantyId, item.itemId)}
                                  />
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                {el.flexibleItems.length < 2 && (
                                  <Tooltip title={el.grouped ? 'Ungroup' : 'Create a group'}>
                                    <IconButton aria-label="group" onClick={() => handleGroupItems(el)}>
                                      {el.grouped ? <ClearIcon /> : <FormatListBulletedIcon />}
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Delete Item">
                                  <IconButton aria-label="remove" onClick={() => handleDelete(item)}>
                                    <DeleteForeverIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Box>
                        </SortableItem>
                      ))}
                    </SortContainer>
                  </RadioGroup>
                  {el.grouped && (
                    <Box sx={{ pb: 2 }}>
                      <FormControl>
                        <InputLabel id="warranties">Add New</InputLabel>
                        <Select
                          labelId="warranties"
                          name="item"
                          value={currentGroupItem || '1'}
                          label="Add New"
                          onChange={onSelectGroupItem}
                          size="small"
                        >
                          <MenuItem value="1">Select an item from the list to add</MenuItem>
                          {filtersData?.map((el) => (
                            <MenuItem key={el.id} value={el.name}>
                              {el.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl sx={{ ml: 2 }}>
                        <InputLabel id="warranties_years">Years</InputLabel>
                        <Select
                          labelId="warranties_years"
                          name="item"
                          value="choose"
                          label="Years"
                          onChange={(event) => onAddNewItemToGroup(event, el.warrantyId)}
                          size="small"
                          disabled={!currentGroupItem}
                        >
                          <MenuItem value="choose">Select number of years</MenuItem>
                          {yearsList?.map((el) => (
                            <MenuItem key={el} value={el}>
                              {el}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </SortableItem>
        ))}
      </SortContainer>

      <Box sx={{ mt: 2 }}>
        <FormControl>
          <InputLabel id="warranties">Add New</InputLabel>
          <Select
            labelId="warranties"
            name="item"
            value={currentItem || '1'}
            label="Add New"
            onChange={onSelectItem}
            size="small"
          >
            <MenuItem value="1">Select an item from the list to add</MenuItem>
            {filtersData?.map((el) => (
              <MenuItem key={el.id} value={el.name}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}>
          <InputLabel id="warranties_years">Years</InputLabel>
          <Select
            labelId="warranties_years"
            name="item"
            value="choose"
            label="Years"
            onChange={onAddNewItem}
            size="small"
            disabled={!currentItem}
          >
            <MenuItem value="choose">Select number of years</MenuItem>
            {yearsList?.map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default WarrantyModule;
