import { Grid } from '@mui/material';

const ProposalViewAwards = () => (
  <Grid container spacing={2}>
    <Grid
      item
      md={4}
      sx={{
        display: { xs: 'none', md: 'block' },
        textAlign: { xs: 'center' },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <img src="/images/awards/1.svg" alt="award" height="70" style={{ marginRight: '15%' }} />
        </Grid>
        <Grid item xs={6}>
          <img src="/images/awards/2.svg" alt="award" height="70" />
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img src="/images/logo.svg" alt="AirMaxx" />
    </Grid>

    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      sx={{ textAlign: { xs: 'center' } }}
    >
    <Grid container spacing={2}>
      <Grid item xs={6} sx={{ display: { md: 'none' } }}>
        <img src="/images/awards/1.svg" alt="award" height="70" style={{ marginRight: '15%' }} />
      </Grid>
      <Grid item xs={6} sx={{ display: { md: 'none' } }}>
        <img src="/images/awards/2.svg" alt="award" height="70" />
      </Grid>
      <Grid item xs={4}>
        <img src="/images/awards/3.png" alt="award" height="70" />
      </Grid>
      <Grid item xs={4}>
        <img src="/images/awards/4.svg" alt="award" height="70" />
      </Grid>
      <Grid item xs={4}>
        <img src="/images/awards/5.svg" alt="award" height="70" />
      </Grid>
    </Grid>
    </Grid>
  </Grid>
);

export default ProposalViewAwards;
