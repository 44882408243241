import { Fragment, useState, useEffect } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import { Avatar, Box, Button, Typography, Grid, Link, Tooltip, Modal } from '@mui/material';

// Icons
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Custom Components
import { Spinner } from '..';
import TimeSlots from './Calendar/TimeSlots';
import TooltipTitle from './Calendar/TooltipTitle';
import CancelModal from './CancelModal';

// Utils
import messages from '../../static/messages';

// Services
import appointmentsServices from '../../services/appointmentsServices';

// Styles
import { modal_style } from '../../static/styles';
import { button } from '../../static/theme-styles';

export const column_heading = {
  height: 115,
  fontSize: 14,
  border: '1px solid #B6C4CD',
  textAlign: 'center',
  background: '#F0F3F5',
  fontWeight: 'bold',
  mb: '2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  p: 2,
};

export const time_slots_styles = {
  height: 65,
  fontSize: 14,
  fontFamily: 'Lato',
  fontWeight: 700,
  p: 0,
  m: 0,
  lineHeight: '65px',
  textAlign: 'center',
  border: '1px solid #B6C4CD',
  background: '#F0F3F5',
  '&:not(:last-of-type)': {
    mb: '2px',
  },
};

const links_styles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  color: '#fff',
  position: 'relative',
};

// External variables
export const defineTime = (index) => {
  if (index === 0) return '07:00 AM';
  if (index === 1) return '08:00 AM';
  if (index === 2) return '09:00 AM';
  if (index === 3) return '10:00 AM';
  if (index === 4) return '11:00 AM';
  if (index === 5) return '12:00 PM';
  if (index === 6) return '01:00 PM';
  if (index === 7) return '02:00 PM';
  if (index === 8) return '03:00 PM';
  if (index === 9) return '04:00 PM';
  if (index === 10) return '05:00 PM';
  if (index === 11) return '06:00 PM';
  if (index === 12) return '07:00 PM';
  if (index === 13) return '08:00 PM';
  if (index === 14) return '09:00 PM';
  if (index === 15) return '10:00 PM';

  return '7:00 AM';
};

const convertTime = (time) => {
  const [hour, minute] = time.split(':');
  const dayTime = minute.split(' ');
  let time24 = hour;

  if (dayTime[1] === 'PM') {
    switch (hour) {
      case '01':
        time24 = '13';
        break;
      case '02':
        time24 = '14';
        break;
      case '03':
        time24 = '15';
        break;
      case '04':
        time24 = '16';
        break;
      case '05':
        time24 = '17';
        break;
      case '06':
        time24 = '18';
        break;
      case '07':
        time24 = '19';
        break;
      case '08':
        time24 = '20';
        break;
      case '09':
        time24 = '21';
        break;
      case '10':
        time24 = '22';
        break;
      default:
        time24 = '12';
        break;
    }
  }

  return {
    h: time24,
    m: dayTime[0],
  };
};

const sortAppointmentsByColumn = (col, arr) => {
  arr.forEach((item) => {
    const [h, m] = moment(item.startTime).format('hh:mm').split(':');
    const timing = moment(item.startTime).format('A');

    col.items.find((el) => {
      if (el.time === `${h}:00 ${timing}`) {
        const elIDX = col.items.indexOf(el);

        col.items[elIDX] = {
          ...item,
          time: el.time,
        };
      }

      return false;
    });
  });
};

const Calendar = ({ date, appointments, queue, columns, setColumns, getAppointments, getQueue }) => {
  const [currentItem, setCurrentItem] = useState(null);
  const [currentCol, setCurrentCol] = useState(null);
  const [isDraggable, setDraggable] = useState(true);
  const [dayOffModal, setDayOffModal] = useState(false);
  const [dayOffId, setDayOffId] = useState(null);
  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelData, setCancelData] = useState();

  const confirm = useConfirm();
  moment.tz.setDefault('America/Los_Angeles');

  // Action functions
  const defineAppointmentsByColumns = () => {
    const defineAppointments = columns?.map((col) => {
      // Appointments
      if (col.info.tech_id) {
        const relatedItems = appointments?.filter(
          (el) => el.user?.id === col.info.tech_id && el.type?.name !== 'Installation' && el.status !== 'Cancelled',
        );

        let withClonedDayOff = [...relatedItems];

        relatedItems.map((el) => {
          if (el.type.name === 'Day Off') {
            let timeDifference = moment(el.startTime).diff(el.endTime, 'hours', true) - 1;

            for (let i = 1; i < -timeDifference; i++) {
              withClonedDayOff.push({
                ...el,
                startTime: moment(el.startTime).add(i, 'hours').format(),
              });
            }
          }
        });

        if (withClonedDayOff.length) sortAppointmentsByColumn(col, withClonedDayOff);
      }

      // Installations
      if (col.info.name === 'Installations') {
        const relatedItems = appointments?.filter((el) => el.type.name === 'Installation');

        if (relatedItems) sortAppointmentsByColumn(col, relatedItems);
      }

      // Queue
      if (col.info.name === 'Queue') {
        if (queue) col.items = queue;
      }

      // Misc
      if (col.info.name === 'Misc') {
        const relatedItems = appointments?.filter((el) => el.status === 'Cancelled');

        if (relatedItems) sortAppointmentsByColumn(col, relatedItems);
      }

      return col;
    });

    setColumns(defineAppointments);
  };

  const onDayOffModalOpen = (id) => {
    setDayOffId(id);
    setDayOffModal(true);
  };

  const onDayOffModalClose = () => {
    setDayOffId(null);
    setCurrentAppointment(null);
    setDayOffModal(false);
  };

  const onCancelModalClose = () => {
    setCancelData();
    setCancelModal(false);
  };

  const onCancelAppointment = (id, start, end) => {
    setCancelModal(true);
    setCancelData({
      id,
      start,
      end,
    });
  };

  // Handler functions
  const dragStartHandler = (e, col, item) => {
    if (isDraggable) {
      setCurrentCol(col);
      setCurrentItem(item);
    }
  };

  const dragEndHandler = (e) => {
    e.target.style.boxShadow = 'none';

    setCurrentCol(null);
    setCurrentItem(null);
  };

  const dragOverHandler = (e) => {
    e.preventDefault();

    const classes = e.target.className.split(' ');

    if (classes.includes('item')) e.target.style.boxShadow = 'inset 0 0 50px 0 #ccc';
  };

  const dragLeaveHandler = (e) => (e.target.style.boxShadow = 'none');

  const dropHandler = (e, col, item) => {
    e.preventDefault();
    e.target.style.boxShadow = 'none';

    if (!isDraggable) return toast.warning('Wait for the database update, a couple of seconds and try again!');

    if (currentItem?.type.name === 'Installation' && col.info.name !== 'Installations')
      return toast.warning('Appointment with type Installation cannot be found in this column!');

    if (currentItem?.type.name !== 'Installation' && col.info.name === 'Installations')
      return toast.warning('This column can only have appointments with the Installation type.');

    if (item.dump) {
      const currentIndex = currentCol.items.indexOf(currentItem);

      const initialStartTime = currentItem.startTime;
      const initialEndTime = currentItem.endTime;
      let timeDifference = moment(initialEndTime).diff(initialStartTime, 'minutes', true);
      if (timeDifference === 0) timeDifference = 60;

      if (currentCol.info.name !== 'Queue') {
        currentCol.items.splice(currentIndex, 1, {
          dump: true,
          time: defineTime(currentIndex),
        });
      } else {
        currentCol.items.splice(currentIndex, 1);
      }

      const dropIndex = col.items.indexOf(item);

      if (col.items[dropIndex].dump) {
        col.items[dropIndex] = {
          ...currentItem,
          user: { id: col.info.tech_id },
          time: defineTime(dropIndex),
        };
      } else {
        col.items.splice(dropIndex, 0, {
          ...currentItem,
          user: { id: col.info.tech_id },
          time: defineTime(dropIndex),
        });
      }

      const newStartTime = moment(date).set({
        hour: convertTime(defineTime(dropIndex)).h,
        minute: convertTime(defineTime(dropIndex)).m,
        second: '0',
      });

      const newEndTime = moment(date)
        .set({
          hour: convertTime(defineTime(dropIndex)).h,
          minute: convertTime(defineTime(dropIndex)).m,
          second: '0',
        })
        .add(timeDifference || 60, 'minutes');

      col.info.name === 'Misc'
        ? onCancelAppointment(currentItem.id, moment.utc(newStartTime), moment.utc(newEndTime))
        : reassign(
            currentItem.id,
            col.info.name === 'Queue' ? null : col.info.tech_id,
            col.info.name === 'Queue' ? null : moment.utc(newStartTime).format(),
            col.info.name === 'Queue' ? null : moment.utc(newEndTime).format(),
            'Active',
          );

      setColumns(
        columns.map((b) => {
          if (b.info.name === col.info.name) return col;
          if (b.info.name === currentCol.info.name) return currentCol;
          return b;
        }),
      );
    }
  };

  const dropCardHandler = (e, col) => {
    if (currentCol.info.name === 'Queue' && col.info.name === 'Queue') return;

    col.items.push(currentItem);
    const currentIndex = currentCol.items?.indexOf(currentItem);

    currentCol.items?.splice(currentIndex, 1, {
      dump: true,
      time: defineTime(currentIndex),
    });

    reassign(currentItem.id, null, null, null, 'Active');

    setColumns(
      columns.map((b) => {
        if (b.info.name === col.info.name) return col;
        if (b.info.name === currentCol.info.name) return currentCol;
        return b;
      }),
    );
  };

  const handleRemoveDayOff = () => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('Day Off'),
    })
      .then(() => removeAppointment(dayOffId))
      .catch(() => toast.info(messages.cancel_message));
  };

  // Async functions
  const reassign = async (aptmID, techID, newStartTime, newEndTime, status, cancelReason) => {
    const updatedAppointment = {
      ...(techID && techID > 0
        ? {
            user: {
              id: techID,
            },
          }
        : { user: { id: null } }),
      startTime: newStartTime ? newStartTime : null,
      endTime: newEndTime ? newEndTime : null,
      ...(status && { status }),
      ...(cancelReason && { reasons: [{ note: cancelReason }] }),
    };

    try {
      const response = await appointmentsServices.update(aptmID, updatedAppointment);

      if (response.status === 200) {
        setDraggable(true);
        getAppointments(date.split('T')[0]);
        getQueue();
        setCancelData();
        setCancelModal(false);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const getDayOffInfo = async (id) => {
    try {
      const response = await appointmentsServices.getAppointmentById(id);

      if (response.status === 200) {
        setCurrentAppointment({
          startTime: moment(response.data.startTime).format('MM/DD/YYYY hh:mm a'),
          endTime: moment(response.data.endTime).format('MM/DD/YYYY hh:mm a'),
          notes: response.data.notes,
          user: response.data.user,
          createdBy: response.data.createdBy,
          id: response.data.id,
        });
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const removeAppointment = async (id) => {
    try {
      const response = await appointmentsServices.remove(id);

      if (response.status === 200) {
        toast.success('Day Off successfully deleted!');
        onDayOffModalClose();
        getAppointments(date.split('T')[0]);
        getQueue();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const cancellation = async () => {
    reassign(cancelData.id, null, cancelData.start, cancelData.end, 'Cancelled', cancelData.reason);
  };

  useEffect(() => {
    if (appointments && queue) defineAppointmentsByColumns();
  }, [appointments, queue]);

  useEffect(() => {
    if (dayOffModal) getDayOffInfo(dayOffId);
  }, [dayOffModal]);

  // Check for data loading
  if (!appointments || !queue) return <Spinner />;

  return (
    <Fragment>
      {columns &&
        columns?.map((col, col_index) => {
          if (col.info.name === 'Queue')
            return (
              <Grid
                container
                key={col_index}
                spacing={0}
                onDragOver={(e) => dragOverHandler(e)}
                onDrop={(e) => dropCardHandler(e, col)}
              >
                <Grid item xs={0.5}>
                  <Typography sx={{ ...column_heading, height: 65 }} variant="body2">
                    {col.info.name}
                  </Typography>
                </Grid>

                {col.items?.map((item, item_index) => (
                  <Grid
                    item
                    xs={1}
                    key={item_index}
                    sx={{ ...time_slots_styles, background: !item.dump && col.info.color, ml: '2px' }}
                    draggable={!item.dump}
                    onDragStart={(e) => dragStartHandler(e, col, item)}
                    onDragEnd={(e) => dragEndHandler(e)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDragLeave={(e) => dragLeaveHandler(e)}
                    onDrop={(e) => dropHandler(e, col, item)}
                    className="item"
                  >
                    <Tooltip arrow placement="right" title={<TooltipTitle info={item} disableInteractive />}>
                      <Link href={`/appointments/${item.id}`} underline="none" sx={links_styles}>
                        <Typography variant="body2">{item.type.name}</Typography>
                        <Typography variant="body2">
                          <strong>{item.property?.address?.split(',')[3]}</strong>
                        </Typography>
                      </Link>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            );
        })}

      <Grid
        container
        spacing={0}
        wrap="nowrap"
        sx={{
          '& > div': {
            '&:nth-last-of-type(2)': { ml: 2 },
            '&:last-of-type': { ml: 2 },
          },
          '& > div:last-of-type': {
            '& > div > a': {
              backgroundColor: 'lightpink',
              position: 'relative',
              zIndex: 0,
              '&::before': {
                content: "''",
                position: 'absolute',
                width: '2px',
                height: '100%',
                backgroundColor: 'red',
                transform: 'rotate(45deg)',
                zIndex: -1,
                opacity: 0.7,
              },
              '&::after': {
                content: "''",
                position: 'absolute',
                width: '2px',
                height: '100%',
                backgroundColor: 'red',
                transform: 'rotate(-45deg)',
                zIndex: -1,
                opacity: 0.7,
              },

              '> p': {
                fontWeight: '800 !important',
              },
            },
          },
        }}
      >
        <Grid item xs={0.5}>
          <TimeSlots />
        </Grid>

        {columns &&
          columns?.map((col, col_index) => {
            if (col.info.name !== 'Queue')
              return (
                <Grid
                  item
                  key={col_index}
                  xs={0.8}
                  // onDragOver={(e) => dragOverHandler(e)}
                  // onDrop={(e) => dropCardHandler(e, col)}
                >
                  <Box sx={{ ...column_heading, ml: '2px' }}>
                    {col.info.photo && <Avatar alt={col.info.name} src={col.info.photo} sx={{ mb: 1 }} />}

                    <Typography variant="body2" sx={{ fontSize: 14, fontFamily: 'Lato', fontWeight: 800 }}>
                      {col.info.name}
                    </Typography>
                  </Box>

                  {col.items?.map((item, item_index) => (
                    <Box
                      key={item_index}
                      sx={{ ...time_slots_styles, background: item.type?.name === 'Day Off' ? 'lightgray' : (!item.dump && col.info.color), ml: '2px' }}
                      draggable={!item.dump && item.type?.name !== 'Day Off'}
                      onDragStart={(e) => dragStartHandler(e, col, item)}
                      onDragEnd={(e) => dragEndHandler(e)}
                      onDragOver={(e) => dragOverHandler(e)}
                      onDragLeave={(e) => dragLeaveHandler(e)}
                      onDrop={(e) => dropHandler(e, col, item)}
                      className="item"
                    >
                      {!item.dump && item.type?.name !== 'Day Off' && (
                        <Tooltip arrow placement="right" title={<TooltipTitle info={item} />} disableInteractive>
                          <Link href={`/appointments/${item?.id}`} underline="none" sx={links_styles}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                              <strong>{item?.property?.address?.split(',')[3]}</strong>
                            </Typography>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                              {item?.type?.name}
                            </Typography>
                            {item.status !== 'Cancelled' && (
                              <Typography variant="body2" sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                {item?.startTime &&
                                  item?.endTime &&
                                  `${moment(item?.startTime).format('hh:mm')} - ${moment(item?.endTime).format(
                                    'hh:mm A',
                                  )}`}
                              </Typography>
                            )}
                            <Box
                              sx={{
                                position: 'absolute',
                                left: 2,
                                top: -20,
                                width: 15,
                                height: 15,
                              }}
                            >
                              {item.status === 'Active' && (
                                <CircleIcon sx={{ color: 'green', mr: 1, width: 15, height: 15 }} />
                              )}

                              {item.status === 'In Progress' && (
                                <CircleIcon sx={{ color: 'orange', mr: 1, width: 15, height: 15 }} />
                              )}

                              {item.status === 'Completed' && (
                                <CheckCircleIcon sx={{ color: 'green', mr: 1, width: 15, height: 15 }} />
                              )}
                            </Box>
                          </Link>
                        </Tooltip>
                      )}
                      {item.type?.name === 'Day Off' && <Box onClick={() => onDayOffModalOpen(item.id)}>Day Off</Box>}
                    </Box>
                  ))}
                </Grid>
              );
          })}
      </Grid>

      <Modal open={dayOffModal} onClose={onDayOffModalClose}>
        <Box sx={modal_style}>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold' }}>
            DAY OFF INFO
          </Typography>

          <Typography variant="body2" sx={{ mb: 2 }}>
            Start Time: {currentAppointment?.startTime}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            End Time: {currentAppointment?.endTime}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Comment: {currentAppointment?.notes[0]?.note}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Created By: {currentAppointment?.createdBy.fullName}
          </Typography>

          <Box>
            <Button variant="contained" sx={button('primary', 'secondary')} onClick={handleRemoveDayOff}>
              Remove Day Off
            </Button>
            <Button variant="contained" sx={{ ...button('secondary'), ml: 2 }} onClick={onDayOffModalClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <CancelModal
        open={cancelModal}
        close={onCancelModalClose}
        data={cancelData}
        setCancelData={setCancelData}
        cancellation={cancellation}
      />
    </Fragment>
  );
};

export default Calendar;
