import { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Typography,
  Modal,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  IconButton,
  Button,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Utils
import messages from '../../static/messages';

// Services
import proposalsServices from '../../services/proposalsServices';

// Styles
const modal_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: '#fff',
  boxShadow: 24,
  p: 4,
};

const InstallationDate = ({ data, id, refresh }) => {
  const confirm = useConfirm();

  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState([]);

  // Action functions
  const addDate = () =>
    setDates((prevState) => [
      ...prevState,
      {
        date: moment().format('MM/DD/YYYY'),
        installationTime: 'AM',
        installersCount: '1',
        isEdited: true,
        index: prevState.length + 1,
      },
    ]);

  const removeDate = (el) => setDates((prevState) => [...prevState.filter((item) => item !== el)]);

  // Handler functions
  const handleClose = () => {
    const isEdited = dates.some((item) => item.isEdited);

    if (isEdited) {
      confirm({
        title: messages.confirm_message.close_drawer,
        description: 'You have unsaved changes! All unsaved data will be lost!',
      })
        .then(() => {
          setOpen(false);
          setDates(data);
        })
        .catch(() => toast.info(messages.cancel_message));
    } else setOpen(false);
  };

  const handleChange = (event, currentItem) => {
    const key = event.target?.name || 'date';
    const val = event.target?.value || moment(event.$d).format('MM/DD/YYYY');

    setDates((prevState) => [
      ...prevState.filter((el) => el !== currentItem),
      {
        ...prevState.filter((el) => el === currentItem)[0],
        [key]: key === 'installersCount' ? (val === 'Invalid date' ? '' : Number(val)) : val,
        isEdited: true,
      },
    ]);
  };

  // Async functions
  const saveDates = async () => {
    try {
      const response = await proposalsServices.update(id, {
        installationSchedule: dates,
      });

      if (response.status === 200) {
        toast.success(`Installation Schedule successfully updated`);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    data.length &&
      setDates(
        data.map((el) => ({
          ...el,
          index: data.indexOf(el) + 1,
        })),
      );
  }, [data]);

  return (
    <Fragment>
      <Box
        sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}
        onClick={() => setOpen(true)}
      >
        <Box sx={{ pt: 1, pb: 1 }}>
          {dates.length > 0 ? (
            dates
              ?.sort(function (a, b) {
                if (a.index < b.index) {
                  return -1;
                }
                if (a.index > b.index) {
                  return 1;
                }
                return 0;
              })
              .map((el, index) => (
                <Typography key={index}>
                  {moment(el.date).utc().format('MM/DD/YYYY')} - {el.installationTime} - {el.installersCount} Installers
                </Typography>
              ))
          ) : (
            <Typography onClick={() => setOpen(true)}>No dates set, click here to add</Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CalendarMonthIcon />
        </Box>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...modal_style, width: 720 }}>
          <Typography variant="h5" align="center">
            <strong>Select dates below to schedule installers</strong>
          </Typography>

          <br />

          {dates.length > 0 ? (
            dates
              .sort(function (a, b) {
                if (a.index < b.index) {
                  return -1;
                }
                if (a.index > b.index) {
                  return 1;
                }
                return 0;
              })
              .map((el, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    gap: 1,
                    mb: 3,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Date"
                      inputFormat="MM/DD/YYYY"
                      value={moment(el.date).utc().format('MM/DD/YYYY') || moment().format('MM/DD/YYYY')}
                      onChange={(event) => handleChange(event, el)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" name="date" onChange={(event) => handleChange(event, el)} />
                      )}
                    />
                  </LocalizationProvider>

                  <ToggleButtonGroup
                    color="primary"
                    value={el.installationTime}
                    exclusive
                    onChange={(event) => handleChange(event, el)}
                    size="small"
                  >
                    <ToggleButton value="AM" name="installationTime">
                      AM
                    </ToggleButton>
                    <ToggleButton value="PM" name="installationTime">
                      PM
                    </ToggleButton>
                    <ToggleButton value="Full Day" name="installationTime">
                      Full Day
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <TextField
                    required
                    name="installersCount"
                    label="Installers"
                    size="small"
                    type="number"
                    value={el.installersCount}
                    onChange={(event) => handleChange(event, el)}
                  />

                  <Tooltip title="Remove">
                    <IconButton onClick={() => removeDate(el)} sx={{ ml: 2 }}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ))
          ) : (
            <Typography variant="body1" align="center" sx={{ mb: 3 }}>
              <strong>Add a first date</strong>
            </Typography>
          )}

          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
            <Button variant="contained" color="error" onClick={addDate}>
              Add New Date
            </Button>
            <Button variant="contained" onClick={saveDates}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default InstallationDate;
