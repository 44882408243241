import { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import { useReactToPrint } from 'react-to-print';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';

import { CssBaseline, Box, Button, Checkbox, FormControlLabel, Typography, Paper, Modal, Link } from '@mui/material';

// Custom Components
import {
  Spinner,
  BrowserTitle,
  EmailProposalModal,
  ProposalViewHeader,
  ProposalViewEditSection,
  ProposalViewAwards,
  ProposalViewContacts,
  ProposalViewSystemEquipment,
  ProposalViewSystemWorks,
  ProposalViewSystemWarranties,
  ProposalViewSystemRebatesAndInstants,
  ProposalViewAdditionalInfo,
} from '../components';

// Utils
import messages from '../static/messages';

// Services
import proposalsServices from '../services/proposalsServices';
import publicServices from '../services/publicServices';

// Styles
const no_print = {
  '@media print': {
    display: 'none',
  },
};

export const container = {
  maxWidth: 950,
  margin: '0 auto',
};

const modal_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: '#e6e6e6',
  boxShadow: 24,
  p: 4,
  canvas: {
    background: '#fff',
    display: 'block',
    margin: '0 auto',
    padding: 1,
    border: '1px solid #ccc',
    '@media screen and (max-width: 600px)': {
      width: '100%',
      height: 300,
    },
  },
  '@media screen and (max-width: 600px)': {
    width: '95%',
    p: 2,
  },
};

const signature_styles = {
  width: 500,
  height: 200,
};

const price_row = { display: 'flex', justifyContent: 'space-between', backgroundColor: '#f2f2f4', p: 2, mt: 2 };

const price_font_weight = { fontWeight: 600 };

// External variables

const ProposalView1 = () => {
  const [proposal, setProposal] = useState();
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [sign, setSign] = useState();
  const [agreement, setAgreement] = useState(false);
  const [token, setToken] = useState();

  const signature = useRef(null);
  const componentRef = useRef(null);

  const queryParams = new URLSearchParams(window.location.search);
  const userToken = queryParams.get('token');

  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();

  // Action functions
  const onAcceptProposal = () => {
    const sign = signature.current.toDataURL();

    setSign(sign);
    setSignatureModalOpen(false);
  };

  const onClearSignature = () => signature.current.clear();

  const onPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  // Handler functions
  const handleSignatureModalOpen = () => setSignatureModalOpen(true);

  const handleEmailModalOpen = () => setEmailModalOpen(!emailModalOpen);

  const handleEmailModalClose = () => setEmailModalOpen(false);

  const handleSignatureModalClose = () => {
    confirm({
      title: messages.confirm_message.close_drawer,
      description: 'All unsaved data will be lost',
    })
      .then(() => setSignatureModalOpen(false))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleClear = () => {
    confirm({
      title: 'Do you want to clear the signature?',
      description: 'All unsaved data will be lost',
    })
      .then(() => onClearSignature())
      .catch(() => toast.info(messages.cancel_message));
  };

  const handlePrint = useReactToPrint({
    content: onPrintContent,
    documentTitle: `proposal_${id}`,
    removeAfterPrint: true,
  });

  const handleChange = (event, system_index, type, el) => {
    const val = Number(event.target.value);

    const updateIndex = () => {
      const proposalCopy = proposal;

      proposalCopy.data.systems[system_index][`${type}`].find((item) => item === el).selectedItemIndex = val;

      return proposalCopy;
    };

    setProposal(() => ({ ...updateIndex() }));
  };

  // Async functions
  const getProposal = async () => {
    try {
      const response = await proposalsServices.getProposalById(id);
      if (response.status === 200) setProposal(response.data);
    } catch (error) {
      if (error?.response?.data.status === 404) {
        navigate('/404');
      } else {
        toast.error(messages.errors.error_data_loaning);
        console.log(error);
      }
    }
  };

  const getPublicProposal = async (userToken) => {
    try {
      const response = await publicServices.getPublicProposal(id, userToken);
      setProposal(response.data);
    } catch (error) {
      if (error.response.data.status === 404) {
        navigate('/404');
      } else if (error.response.data.status === 401) {
        navigate('/401');
      } else {
        toast.error(messages.errors.error_data_loaning);
        console.log(error);
      }
    }
  };

  const updatePublicProposal = async () => {
    try {
      const response = await publicServices.updatePublicProposal(id, token, {
        data: {
          ...proposal.data,
        },
        signature: {
          sign: sign,
        },
      });

      if (response.status === 200) {
        toast.success('Accepting was successful!');
        getPublicProposal(userToken);
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    if (userToken) {
      setToken(userToken);
      getPublicProposal(userToken);
    } else {
      getProposal();
    }
  }, []);

  useEffect(() => {
    if (sign) updatePublicProposal();
  }, [sign]);

  // Check for data loading
  if (!proposal) return <Spinner />;

  return (
    <Fragment>
      <CssBaseline />
      <BrowserTitle title="AirMaxx Pro | Proposal View" />

      <ProposalViewHeader
        handlePrint={handlePrint}
        handleEmailModalOpen={handleEmailModalOpen}
        handleSignatureModalOpen={handleSignatureModalOpen}
        isAccepted={!!proposal.signature}
        token={!!token}
        totalPay={proposal.data?.totalPay}
      />

      <Box component="main" sx={{ background: '#f2f2f4', padding: '80px 0' }}>
        <ProposalViewEditSection id={id} client={!!token} />

        <Paper sx={{ maxWidth: 950, margin: '1rem auto 0', padding: '2.5rem 1.5rem' }} ref={componentRef}>
          <ProposalViewAwards />

          {console.log(proposal)}

          <ProposalViewContacts
            id={proposal.publicId}
            property={proposal.property}
            billingPlace={proposal.billingPlace}
            issueDate={proposal.createdAt}
            installationSchedule={proposal.installationSchedule}
            createdBy={proposal.createdBy}
          />

          <Typography variant="h4" sx={{ textAlign: 'center', m: 4, fontWeight: 600 }}>
            {proposal.documentType}
          </Typography>

          {proposal.data?.systems?.map((system, system_index) => (
            <Box key={system_index} sx={{ mb: 3, px: 5 }}>
              {system.equipments.length > 0 && (
                <ProposalViewSystemEquipment
                  arr={system.equipments}
                  handleChange={handleChange}
                  system_index={system_index}
                  title={system.name}
                />
              )}

              {system.scopeOfWorks.length > 0 && (
                <ProposalViewSystemWorks
                  arr={system.scopeOfWorks}
                  handleChange={handleChange}
                  system_index={system_index}
                />
              )}

              {system.warranties.length > 0 && (
                <ProposalViewSystemWarranties
                  arr={system.warranties}
                  handleChange={handleChange}
                  system_index={system_index}
                />
              )}

              {(system.instants.length > 0 || system.rebates.length > 0) && (
                <ProposalViewSystemRebatesAndInstants
                  rebates={system.rebates}
                  instants={system.instants}
                  handleChange={handleChange}
                  system_index={system_index}
                />
              )}
            </Box>
          ))}

          {/* <Box sx={price_row}>
            <Typography variant="h6" sx={price_font_weight}>
              Total After:
            </Typography>
            <Typography variant="h6" sx={price_font_weight}>
              {proposal.data?.totalAfter?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }) || 0}
            </Typography>
          </Box> */}

          <Box sx={price_row}>
            <Typography variant="h6" sx={price_font_weight}>
              Total to Pay:
            </Typography>
            <Typography variant="h6" sx={price_font_weight}>
              {proposal.data?.totalPay > 0
                ? proposal.data?.totalPay?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }) || 0
                : 0}
            </Typography>
          </Box>

          {proposal.paymentNotes && (
            <Box sx={price_row}>
              <Typography variant="h6" sx={price_font_weight}>
                Payment Notes:
              </Typography>
              <Typography variant="h6" sx={price_font_weight}>
                {proposal.paymentNotes}
              </Typography>
            </Box>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
            {!proposal.signature && (
              <Button
                variant="contained"
                color="success"
                size="large"
                onClick={handleSignatureModalOpen}
                sx={no_print}
                disabled={!token}
              >
                Accept Proposal
              </Button>
            )}

            {!!proposal.signature && (
              <Fragment>
                <Box sx={{ border: '1px solid #ccc', padding: 1 }}>
                  <img src={proposal.signature.sign} alt="signature" style={{ verticalAlign: 'top' }} />
                </Box>
                <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
                  Accepted on {moment.utc(proposal.signature?.createdAt).format('MMM Do YYYY, h:mm:ss a')}
                </Typography>
              </Fragment>
            )}

            <Link
              href="https://airmaxx.com/wp-content/uploads/2022/06/Home-Improvement-Construction-Contract-_-Owner-and-Contractor.pdf"
              underline="hover"
              target="_blank"
              rel="noReferrer"
              color="primary"
              sx={{
                ...no_print,
                mt: 2,
              }}
            >
              Terms and Conditions
            </Link>
          </Box>
        </Paper>

        {proposal.informationForCustomer?.length > 0 && (
          <ProposalViewAdditionalInfo info={proposal.informationForCustomer} />
        )}
      </Box>

      <Modal open={signatureModalOpen} onClose={handleSignatureModalClose}>
        <Box sx={modal_style}>
          <SignatureCanvas penColor="black" canvasProps={signature_styles} ref={signature} />

          <FormControlLabel
            onChange={() => setAgreement(!agreement)}
            control={<Checkbox checked={agreement} />}
            label={`I agree with the Terms and Conditions`}
            sx={{ pl: 1, pr: 1 }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, pl: 1, pr: 1, mt: 1 }}>
            <Button variant="contained" color="error" size="small" onClick={handleClear}>
              Clear
            </Button>
            <Button variant="contained" color="success" size="small" onClick={onAcceptProposal} disabled={!agreement}>
              Accept
            </Button>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Link
              href="https://airmaxx.com/wp-content/uploads/2022/06/Home-Improvement-Construction-Contract-_-Owner-and-Contractor.pdf"
              underline="hover"
              target="_blank"
              rel="noReferrer"
              color="primary"
              sx={{
                ...no_print,
              }}
            >
              Terms and Conditions
            </Link>
          </Box>
        </Box>
      </Modal>

      {!token && <EmailProposalModal isOpen={emailModalOpen} onClose={handleEmailModalClose} data={proposal} />}

      <ToastContainer />
    </Fragment>
  );
};

export default ProposalView1;
