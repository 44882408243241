import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';

// Icons
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// Custom components
import { Spinner } from '../../../';

// Utils
import messages from '../../../../static/messages';

// Services
import scopeOfWorkServices from '../../../../services/scopeOfWorkServices';

// Styles
import { proposal_grouped_items } from '../../../../static/styles';

// Dragging

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => <DragIndicatorIcon sx={{ cursor: 'grab' }} />); 
const SortableItem = SortableElement(({children}) => <div>{children}</div>);
const SortContainer = SortableContainer(({children}) => <div>{children}</div>);

const ScopeOfWorkModule = ({ systemName, data, setSystems, system }) => {
  const [scopeOfWork, setScopeOfWork] = useState([]);
  const [filtersData, setFiltersData] = useState();

  const confirm = useConfirm();

  // Action functions
  const onAddNewItem = (event) => {
    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          scopeOfWorks: system.scopeOfWorks.concat({
            flexibleItems: [
              {
                item: event.target.value,
                price: null,
              },
            ],
            selectedItemIndex: 0,
          })
        }
      )
    );
  };

  const onAddNewItemToGroup = (event, workID) => {
    const newItem = event.target.value;

    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          scopeOfWorks: scopeOfWork.map((el) =>
            el.workId !== workID ? el : {
              ...el,
              flexibleItems: el.flexibleItems.concat({
                item: newItem,
                price: null,
                itemId: el.flexibleItems.length,
              }),
            }
          )
        }
      )
    );
  };

  const onRemoveItem = (item) => {
    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          scopeOfWorks: scopeOfWork
            .map((el) => !el.flexibleItems.includes(item) ? el : {
              ...el,
              flexibleItems: el.flexibleItems.filter((i) => i !== item)
            })
            .filter((el) => el.flexibleItems.length),
        }
      )
    );
  };

  // Handler functions
  const handleChange = (event, workID, itemID) => {
    const key = event.target.name;
    const val = event.target.value;

    const updatedWorks = scopeOfWork.map((el) =>
      el.workId !== workID ? el : {
        ...el,
        ...(key === 'selectedItemIndex'
          ? { selectedItemIndex: Number(val) || 0 }
          : {
            flexibleItems: el.flexibleItems.map((item) => {
              if (item.itemId !== itemID) return item;
              if (key === 'price') return { ...item, price: Number(val) || 0 };
              if (key === 'description') return { ...item, item: { ...item.item, description: val } };
              return { ...item, [key]: val }
            })
          }
        ),
      }
    );

    setSystems((prevState) => 
      prevState.map((system) => system.name !== systemName ? system : { ...system, scopeOfWorks: updatedWorks})
    );
  };

  const handleDelete = (item) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('item'),
    })
      .then(() => onRemoveItem(item))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleGroupItems = (item) => {
    setSystems((prevState) => prevState.map((system) =>
      system.name !== systemName
        ? system
        : {
          ...system,
          scopeOfWorks: scopeOfWork.map(el => el !== item ? el : { ...el, grouped: !item.grouped })
        }
    ));
  };

  // Async functions
  const getScopeOfWorkList = async () => {
    try {
      const response = await scopeOfWorkServices.getAllWorks(0, 1000000, true);

      if (response.status === 206) {
        let unique_values = response.data.data.reduce(
          (acc, el) => {
            if (acc.map[el.description]) return acc;

            acc.map[el.description] = true;
            acc.works.push(el);

            return acc;
          },
          {
            map: {},
            works: [],
          },
        );

        setFiltersData(unique_values.works);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getScopeOfWorkList();
    if (!data) return;

    const listOfWorks = data.map((el, workId) => ({
      ...el,
      workId,
      grouped: !!(el.grouped || el.flexibleItems.length > 1),
      flexibleItems: el.flexibleItems.map((item, itemId) => ({ ...item, itemId })),
    }));

    setScopeOfWork(listOfWorks);
  }, [data]);

  if (!filtersData) return <Spinner />;

  return (
    <Box>
      <Typography variant="body1">
        <strong>Scope of Work</strong>
      </Typography>

      <SortContainer
        useDragHandle
        onSortEnd={({oldIndex, newIndex}) => {
          system.scopeOfWorks = arrayMoveImmutable(scopeOfWork, oldIndex, newIndex);
          setScopeOfWork(system.scopeOfWorks);
        }}
      >
        {scopeOfWork.map((el, index) => (
          <SortableItem key={el.workId} index={index}>
            <Box sx={{ mt: 2 }}>
              <Grid container columnSpacing={1}>
                {scopeOfWork.length > 1 && (
                  <Grid item sx={{ maxWidth: 42, pt: '8px !important', pl: '0 !important' }}>
                    <DragHandle />
                  </Grid>
                )}
                <Grid item xs={11} sx={{ ...(el.grouped && proposal_grouped_items)}}>
                  <RadioGroup value={el.selectedItemIndex}>
                    <SortContainer
                      useDragHandle
                      onSortEnd={({oldIndex, newIndex}) => {
                        system.scopeOfWorks = scopeOfWork.map(work => {
                          if (work !== el) return work;
                          const selectedItem = work.flexibleItems[el.selectedItemIndex];
                          const flexibleItems = arrayMoveImmutable(work.flexibleItems, oldIndex, newIndex);
                          return {
                            ...work,
                            selectedItemIndex: flexibleItems.indexOf(selectedItem),
                            flexibleItems
                          };
                        });
                        setScopeOfWork(system.scopeOfWorks);
                      }}
                    >
                      {el.flexibleItems?.map((item, index) => (
                        <SortableItem key={item.itemId} index={index}>
                          <Box
                            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                            className="item"
                          >
                            <Grid container columnSpacing={1} rowSpacing={2} sx={{ mb: 2 }}>
                              {el.grouped && el.flexibleItems.length > 1 && (
                                <Grid item sx={{ maxWidth: 42, pt: '24px !important' }}>
                                  <DragHandle />
                                </Grid>
                              )}
                              {el.grouped && el.flexibleItems.length > 1 && (
                                <Grid item sx={{ maxWidth: 42, pl: '0 !important' }}>
                                  <Radio
                                    name="selectedItemIndex"
                                    value={index}
                                    checked={index === el.selectedItemIndex}
                                    onChange={(event) => handleChange(event, el.workId, item.itemId)}
                                  />
                                </Grid>
                              )}

                              {/* DESCRIPTION */}
                              <Grid item xs={12} md={8}>
                                <TextField
                                  name="description"
                                  label="Description"
                                  value={item.item.description}
                                  fullWidth
                                  size="small"
                                  onChange={event => handleChange(event, el.workId, item.itemId)}
                                />
                              </Grid>

                              {/* PRICE */}
                              <Grid item xs={12} md={2}>
                                <FormControl fullWidth size="small">
                                  <InputLabel required htmlFor="price">
                                    Price
                                  </InputLabel>
                                  <OutlinedInput
                                    required
                                    id="price"
                                    name="price"
                                    value={item.price !== null ? item.price : item.item.price}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Price"
                                    onChange={(event) => handleChange(event, el.workId, item.itemId)}
                                  />
                                </FormControl>
                              </Grid>

                              {/* BUTTONS */}
                              <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                {el.flexibleItems.length < 2 && (
                                  <Tooltip title={el.grouped ? 'Ungroup' : 'Create a group'}>
                                    <IconButton aria-label="group" onClick={() => handleGroupItems(el)}>
                                      {el.grouped ? <ClearIcon /> : <FormatListBulletedIcon />}
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Delete Item">
                                  <IconButton aria-label="remove" onClick={() => handleDelete(item)}>
                                    <DeleteForeverIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Box>
                        </SortableItem>
                      ))}
                    </SortContainer>
                  </RadioGroup>
                  {el.grouped && (
                    <FormControl sx={{ pb: 2 }}>
                      <InputLabel id="scope_of_work">Add New</InputLabel>
                      <Select
                        labelId="scope_of_work"
                        name="item"
                        value="1"
                        label="Add New"
                        onChange={(event) => onAddNewItemToGroup(event, el.workId)}
                        size="small"
                      >
                        <MenuItem value="1">Select an item from the list to add</MenuItem>
                        {filtersData?.map((el) => (
                          <MenuItem key={el.id} value={el}>
                            {el.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Box>
          </SortableItem>
        ))}
      </SortContainer>

      <FormControl sx={{ mt: 2 }}>
        <InputLabel id="scope_of_work">Add New</InputLabel>
        <Select
          labelId="scope_of_work"
          name="item"
          value="1"
          label="Add New"
          onChange={(event) => onAddNewItem(event)}
          size="small"
        >
          <MenuItem value="1">Select an item from the list to add</MenuItem>
          {filtersData?.map((el) => (
            <MenuItem key={el.id} value={el}>
              {el.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ScopeOfWorkModule;
