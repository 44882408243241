import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import { BrowserTitle } from '../components';

const styles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  svg: {
    display: 'inline-block',
    animation: 'spooky 2s alternate infinite linear;',
    margin: '0 10px',
  },
  h3: {
    marginTop: 2,
  },
  h6: {
    marginTop: 2,
  },
  button: {
    marginTop: 4,
  },
};

const Page401 = () => {
  const navigate = useNavigate();

  return (
    <Box component="section" sx={styles}>
      <BrowserTitle title="No Access | AirMaxx Pro" />
      <Typography variant="h1" color="#528cce">
        4<Ghost />4
      </Typography>

      <Typography variant="h3">Page not found</Typography>
      <Typography variant="subtitle1">
        Your access to this resource might have expired
      </Typography>

      <Button variant="contained" onClick={() => navigate('/')}>
        Visit AirMaxx Pro
      </Button>
    </Box>
  );
};

export default Page401;

const Ghost = () => (
  <svg viewBox="0 0 92.54 122.88" width={55} fill="#528cce">
    <g>
      <path d="M46.27,0c12.73,0,24.29,5.2,32.68,13.59c8.38,8.38,13.59,19.95,13.59,32.68v72.94c0,1.63-1.32,2.94-2.94,2.94 c-0.85,0-1.62-0.36-2.16-0.95l-8.7-8.21l-8.87,8.66c-1.14,1.11-2.95,1.1-4.08,0.01l0,0l-8.8-8.49l-8.84,8.84 c-1.15,1.15-3.01,1.15-4.16,0c-0.05-0.05-0.09-0.1-0.14-0.14L34.87,113l-8.31,8.82c-1.11,1.18-2.97,1.24-4.15,0.13 c-0.03-0.03-0.06-0.06-0.09-0.09l-9.08-8.87l-8.29,7.77c-1.18,1.1-3.03,1.04-4.14-0.14c-0.53-0.56-0.79-1.28-0.79-2H0V46.27 c0-12.73,5.2-24.29,13.59-32.68C21.97,5.2,33.54,0,46.27,0L46.27,0z M33.13,44.81c6.33,0,11.47,5.13,11.47,11.47 c0,6.33-5.13,11.47-11.47,11.47c-6.33,0-11.47-5.13-11.47-11.47C21.66,49.95,26.8,44.81,33.13,44.81L33.13,44.81z M64.85,44.81 c6.33,0,11.47,5.13,11.47,11.47c0,6.33-5.13,11.47-11.47,11.47c-6.33,0-11.47-5.13-11.47-11.47 C53.38,49.95,58.51,44.81,64.85,44.81L64.85,44.81z M74.78,17.75C67.46,10.43,57.37,5.89,46.27,5.89c-11.1,0-21.2,4.54-28.52,11.86 C10.43,25.07,5.89,35.17,5.89,46.27v65.58l5.25-4.92l0.04-0.04c1.13-1.16,2.99-1.19,4.15-0.06l9,8.8l8.32-8.83l0,0l0.05-0.05 c1.14-1.16,2.99-1.17,4.15-0.03l9.19,9.08l8.82-8.82l0.01,0.01c1.13-1.13,2.96-1.15,4.11-0.04l8.83,8.52l8.79-8.59 c1.12-1.14,2.94-1.19,4.11-0.09l5.94,5.61V46.27C86.65,35.17,82.1,25.07,74.78,17.75L74.78,17.75z" />
    </g>
  </svg>
);
