import { Routes, Route, Navigate } from 'react-router-dom';

import { Layout, RequireAuth } from '../components';

import {
  Welcome,
  Login,
  Appointments,
  Clients,
  // Installers,
  // Invoices,
  Items,
  Logs,
  // Messages,
  Properties,
  Proposals,
  // Reminders,
  Settings,
  Profile,
  Property,
  Client,
  Proposal,
  Page404,
  Page401,
  ProposalView,
  Appointment,
  Schedule,
  Timer,
  Support,
} from '../pages';

import AppointmentCreate from '../pages/AppointmentCreate';

import permissions from '../static/permissions';

export const useRoutes = (isAuthenticated, userData) => {
  if (isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            path="/"
            element={userData?.role === 'technician' ? <Navigate to="/schedule" /> : <Navigate to="/appointments" />}
          />

          <Route path="/welcome" element={<Welcome />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/401" element={<Page401 />} />

          <Route path="/profile" element={<Profile />} />

          <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.matrix} />}
          >
            <Route path="appointments" element={<Appointments />} />
            <Route path="appointments/create" element={<AppointmentCreate />} />
          </Route>

          <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.schedule} />}
          >
            <Route path="appointments/:id" element={<Appointment />} />
          </Route>

          <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.clients} />}
          >
            <Route path="clients" element={<Clients />} />
            <Route path="clients/:id" element={<Client />} />
          </Route>

          {/* <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.installers} />}
          >
            <Route path="installers" element={<Installers />} />
          </Route> */}

          {/* <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.invoices} />}
          >
            <Route path="invoices" element={<Invoices />} />
          </Route> */}

          <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.items} />}
          >
            <Route path="items" element={<Items />} />
            <Route path="items/:tab" element={<Items />} />
          </Route>

          {/* <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.messages} />}
          >
            <Route path="messages" element={<Messages />} />
          </Route> */}

          <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.properties} />}
          >
            <Route path="properties" element={<Properties />} />
            <Route path="properties/:id" element={<Property />} />
          </Route>

          <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.proposals} />}
          >
            <Route path="proposals" element={<Proposals />} />
            <Route path="proposals/:id" element={<Proposal />} />
          </Route>

          {/* <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.reminders} />}
          >
            <Route path="reminders" element={<Reminders />} />
          </Route> */}

          <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.settings} />}
          >
            <Route path="settings" element={<Settings />} />
            <Route path="settings/:tab" element={<Settings />} />
          </Route>

          <Route element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.logs} />}>
            <Route path="logs" element={<Logs />} />
            <Route path="logs/:tab" element={<Logs />} />
          </Route>

          <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.schedule} />}
          >
            <Route path="schedule" element={<Schedule />} />
          </Route>

          <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.timer} />}
          >
            <Route path="time-tracking" element={<Timer />} />
          </Route>

          <Route
            element={<RequireAuth userPermissions={userData?.permissions} allowedPermission={permissions.schedule} />}
          >
            <Route path="support" element={<Support />} />
          </Route>
        </Route>
        <Route path="proposals/view/:id" element={<ProposalView />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="proposals/view/:id" element={<ProposalView />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="/401" element={<Page401 />} />

      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
