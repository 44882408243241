import { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import { Box, Drawer, Grid, TextField, Button, Divider, Typography } from '@mui/material';

// Utils
import messages from '../../static/messages';
import { internationalPhoneFormat, validateEmail } from '../../utils/formatter';

// Styles
import { button } from '../../static/theme-styles';

// External variables
const required_fields = ['firstName', 'phoneNumber'];

const ClientDrawer = (props) => {
  const { currentClient, setCurrentClient, createClient, updateClient } = props;
  const [errors, setErrors] = useState({});

  // Action functions
  const resetErrors = () => {
    setErrors({});
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  // Handler functions
  const handleClose = () => props.onCloseDrawer(resetErrors);

  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    switch (key) {
      case 'phoneNumber':
      case 'fax':
      case 'mobile':
        setCurrentClient((prevState) => ({
          ...prevState,
          [key]: val === '1' ? '' : internationalPhoneFormat(val),
          isEdited: true,
        }));
        break;
      default:
        setCurrentClient((prevState) => ({
          ...prevState,
          [key]: val,
          isEdited: true,
        }));
        break;
    }
  };

  const handleValidate = () => {
    resetErrors();

    required_fields.forEach((field) => {
      if (currentClient[field] === '') {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });

    if (currentClient.email && !validateEmail(currentClient.email)) {
      toast.error(messages.errors.invalid_format('Email'));
      setErrors((prevState) => ({
        ...prevState,
        email: true,
      }));
    }

    if (currentClient.phoneNumber.length !== 12) {
      toast.error(messages.errors.invalid_format('Phone'));
      setErrors((prevState) => ({
        ...prevState,
        phoneNumber: true,
      }));
    }

    if (currentClient.mobile && currentClient.mobile.length !== 12) {
      toast.error(messages.errors.invalid_format('Mobile'));
      setErrors((prevState) => ({
        ...prevState,
        mobile: true,
      }));
    }

    if (currentClient.fax && currentClient.fax.length !== 12) {
      toast.error(messages.errors.invalid_format('Fax'));
      setErrors((prevState) => ({
        ...prevState,
        fax: true,
      }));
    }
  };

  // Hooks
  useEffect(() => {
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  }, []);

  useEffect(() => {
    if (currentClient?.isEdited) {
      !Object.values(errors).includes(true) && (props.isNewClient ? createClient() : updateClient());
    }
  }, [errors]);

  return (
    <Drawer anchor="right" open={props.drawer} onClose={handleClose}>
      <Box>
        {currentClient && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  {props.isNewClient ? 'Add New Client' : 'Update Client'}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="companyName"
                name="companyName"
                label="Company"
                defaultValue={currentClient.companyName}
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                id="firstName"
                name="firstName"
                label="Fist Name"
                defaultValue={currentClient.firstName}
                fullWidth
                onChange={handleChange}
                error={errors.firstName}
                helperText={errors.firstName && messages.errors.required_field}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                defaultValue={currentClient.lastName}
                fullWidth
                onChange={handleChange}
                error={errors.lastName}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="email"
                name="email"
                label="Email"
                defaultValue={currentClient.email}
                fullWidth
                onChange={handleChange}
                error={errors.email}
              />
            </Grid>

            <Grid item xs={6}>
              <InputMask
                mask="+1 (999) 999-9999"
                value={currentClient.phoneNumber}
                disabled={false}
                maskChar=" "
                onChange={handleChange}
              >
                {() => (
                  <TextField
                    required
                    name="phoneNumber"
                    id="phoneNumber"
                    label="Phone"
                    fullWidth
                    error={errors.phoneNumber}
                    helperText={errors.phoneNumber ? currentClient.phoneNumber ? messages.errors.invalid_format('Phone') : messages.errors.required_field : false}
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={6}>
              <InputMask
                mask="+1 (999) 999-9999"
                value={currentClient.mobile}
                disabled={false}
                maskChar=" "
                onChange={handleChange}
              >
                {() => (
                  <TextField
                    name="mobile"
                    id="mobile"
                    label="Mobile"
                    fullWidth
                    error={errors.mobile}
                    helperText={errors.mobile && messages.errors.invalid_format_helper}
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={6}>
              <InputMask
                mask="+1 (999) 999-9999"
                value={currentClient.fax}
                disabled={false}
                maskChar=" "
                onChange={handleChange}
              >
                {() => (
                  <TextField
                    name="fax"
                    id="fax"
                    label="Fax"
                    fullWidth
                    error={errors.fax}
                    helperText={errors.fax && messages.errors.invalid_format_helper}
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="success"
                sx={{ ...button('primary', 'secondary'), mr: 3 }}
                onClick={handleValidate}
                disabled={!currentClient.isEdited}
              >
                {props.isNewClient ? 'Add New Client' : 'Update Client'}
              </Button>

              <Button variant="contained" color="error" sx={button('secondary')} onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Drawer>
  );
};

export default ClientDrawer;
