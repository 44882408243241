import { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Accordion, AccordionSummary, AccordionDetails, Box, Button, Typography } from '@mui/material';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MailIcon from '@mui/icons-material/Mail';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// Custom Components
import {
  Spinner,
  BrowserTitle,
  ProposalDetailsTable,
  ProposalSystemTable,
  ProposalPriceTable,
  ProposalCustomerInfoTable,
  EmailProposalModal,
} from '../components';

// Utils
import messages from '../static/messages';

// Services
import proposalsServices from '../services/proposalsServices';

// Hooks
import useAccordion from '../hooks/useAccordion';

// Styles
import { button } from '../static/theme-styles';

// External variables
const blocks = ['details', 'system', 'total_price', 'additional_info'];

const Proposal = () => {
  const [proposal, setProposal] = useState();
  const [systems, setSystems] = useState([]);
  const [systemPrices, setSystemPrices] = useState([]);
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { open, accordionToggle } = useAccordion(blocks);

  // Action functions
  const onCalculateTotalPrice = () => {
    if (systems?.length > 0) {
      const sum = systems.map((el) => {
        function sum(total, item) {
          item.flexibleItems.map((item) =>
            total += item.price === null ? (Number(item.item.price) || 0) : (Number(item.price) || 0),
          );
          return total;
        }

        const equipments_price = el.equipments.reduce(sum, 0);
        const works_price = el.scopeOfWorks.reduce(sum, 0);
        const warranties_price = el.warranties.reduce(sum, 0);
        const instants_price = el.instants.reduce(sum, 0);
        const rebates_price = el.rebates.reduce(sum, 0);

        return {
          total: (el.installationPrice || 0) + equipments_price + works_price + warranties_price,
          instants_price,
          rebates_price,
        };
      });

      setSystemPrices(sum);
    } else {
      setSystemPrices([
        {
          instants_price: 0,
          rebates_price: 0,
          total: 0,
        },
      ]);
    }
  };

  // Handler functions
  const handleEmailModalOpen = () => updateSystems().then(() => setEmailModalOpen(true));

  const handleEmailModalClose = () => setEmailModalOpen(false);

  // Async functions
  const getProposalData = async () => {
    try {
      const response = await proposalsServices.getProposalById(id);
      setProposal(response.data);
    } catch (error) {
      if (error.response.data.status === 404) {
        navigate('/404');
      } else {
        toast.error(messages.errors.error_data_loaning);
        console.log(error);
      }
    }
  };

  const updateSystems = async () => {
    try {
      const response = await proposalsServices.update(id, {
        data: {
          systems: systems,
        },
      });

      if (response.status === 200) {
        toast.success('Systems successfully updated');
        getProposalData();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const seeProposal = async () => {
    updateSystems();
    navigate(`/proposals/view/${proposal.id}`);
  };

  // Hooks
  useEffect(() => {
    getProposalData();
    accordionToggle('details');
  }, []);

  useEffect(() => {
    onCalculateTotalPrice();
  }, [systems]);

  // Check for data loading
  if (!proposal) return <Spinner />;

  return (
    <Fragment>
      <Box component="section">
        <BrowserTitle title="Proposal | AirMaxx Pro" />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',

            flexWrap: {
              xs: 'wrap',
              md: 'nowrap',
            },
            gap: 2,
            mb: 2,
          }}
        >
          <Button
            variant="text"
            onClick={() =>
              navigate(
                `/proposals${
                  location.state ? `?page=${location?.state?.page}&per_page=${location?.state?.rowsPerPage}` : ''
                }`,
              )
            }
            startIcon={<KeyboardBackspaceIcon />}
          >
            Back to Proposals
          </Button>

          <Button onClick={seeProposal} variant="text" endIcon={<PictureAsPdfIcon />}>
            Preview proposal
          </Button>

          <Button onClick={handleEmailModalOpen} variant="text" endIcon={<MailIcon />}>
            Email this proposal
          </Button>
        </Box>

        {/* Proposal Details */}
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={open.details}
          onChange={() => accordionToggle('details')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Proposal Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProposalDetailsTable {...{ proposal, id, refresh: getProposalData }} />
          </AccordionDetails>
        </Accordion>

        {/* System */}
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={open.system}
          onChange={() => accordionToggle('system')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Systems</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProposalSystemTable {...{ proposal, id, refresh: getProposalData, systems, setSystems, updateSystems }} />
          </AccordionDetails>
        </Accordion>

        {/* Total Price */}
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={open.total_price}
          onChange={() => accordionToggle('total_price')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Total Price</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProposalPriceTable {...{ proposal, id, refresh: getProposalData, prices: systemPrices }} />
          </AccordionDetails>
        </Accordion>

        {/* Information for Customer  */}
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={open.additional_info}
          onChange={() => accordionToggle('additional_info')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Information for Customer</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProposalCustomerInfoTable {...{ proposal, id, refresh: getProposalData }} />
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={true}>
          <AccordionDetails>
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleEmailModalOpen}
                size="large"
                variant="contained"
                sx={{ ...button('green', 'secondary'), fontWeight: 800, textTransform: 'uppercase' }}
                endIcon={<MailIcon />}
              >
                Email this proposal
              </Button>
              <Button
                onClick={seeProposal}
                size="large"
                variant="contained"
                sx={{
                  ...button('secondary'),
                  ml: { md: 2, xs: 0 },
                  mt: { md: 0, xs: 2 },
                  fontWeight: 800,
                  textTransform: 'uppercase',
                }}
                endIcon={<PictureAsPdfIcon />}
              >
                Preview proposal
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {emailModalOpen && <EmailProposalModal isOpen={emailModalOpen} onClose={handleEmailModalClose} data={proposal} />}
    </Fragment>
  );
};

export default Proposal;
