import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// Custom components
import { Spinner } from '../../../';

// Utils
import messages from '../../../../static/messages';

// Services
import equipmentServices from '../../../../services/equipmentServices';

// Styles
import { proposal_grouped_items } from '../../../../static/styles';

// Dragging

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => <DragIndicatorIcon sx={{ cursor: 'grab' }} />); 
const SortableItem = SortableElement(({children}) => <div>{children}</div>);
const SortContainer = SortableContainer(({children}) => <div>{children}</div>);

const EquipmentModule = ({ systemName, data, setSystems, system }) => {
  const [equipmentList, setEquipmentList] = useState([]);
  const [filtersData, setFiltersData] = useState();

  const confirm = useConfirm();

  // Action functions
  const onAddNewItem = (event) => {
    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          equipments: system.equipments.concat({
            flexibleItems: [
              { price: null, item: { [event.target.name]: event.target.value } },
            ],
            selectedItemIndex: 0,
          }),
        },
      )
    );
  };

  const onItemReplacement = (item, equipmentId, itemId) => {
    setSystems((prevState) => prevState.map((system) => system.name !== systemName ? system : {
      ...system,
      equipments: equipmentList.map((el) => el.equipmentId !== equipmentId ? el : {
        ...el,
        flexibleItems: el.flexibleItems.map((flexibleItem) =>
          flexibleItem.itemId !== itemId ? flexibleItem : { ...flexibleItem, item }
        ),
    }),
    }));
  };

  const onAddNewItemToGroup = (event, equipmentID) => {

    setSystems((prevState) =>
      prevState.map((el) => el.name !== systemName ? el : {
        ...el,
        equipments: equipmentList.map((el) =>
          el.equipmentId !== equipmentID ? el : {
            ...el,
            flexibleItems: el.flexibleItems.concat({
              itemId: el.flexibleItems.length,
              item: { [event.target.name]: event.target.value, },
              price: null,
            }),
          }
        ),
      }),
    );
  };

  const onRemoveItem = (item) => {
    setSystems((prevState) =>
      prevState.map((system) => system.name !== systemName ? system : {
        ...system,
        equipments: equipmentList
          .map((el) => !el.flexibleItems.includes(item) ? el : {
            ...el,
            flexibleItems: el.flexibleItems.filter((i) => i !== item),
          })
          .filter((el) => el.flexibleItems.length),
      })
    );
  };

  // Handler functions
  const handleChange = (event, equipmentId, itemId) => {
    const key = event.target.name;
    const val = event.target.value;

    setSystems((prevState) => prevState.map((system) => system.name !== systemName ? system : {
      ...system,
      equipments: equipmentList.map((el) => el.equipmentId !== equipmentId ? el : {
        ...el,
        ...(key === 'selectedItemIndex'
          ? { selectedItemIndex: Number(val) }
          : {
            flexibleItems: el.flexibleItems.map((item) => {
              if (item.itemId !== itemId) return item;
              if (key === 'type') return { ...item, item: { type: val } };
              if (key === 'make') return { ...item, item: { type: item.item.type, make: val } };
              if (key === 'model') return { ...item, item: { type: item.item.type, make: item.item.make, model: val } };
              return { ...item, item: { ...item.item, [key]: val } };
            }),
          }
        ),
      }),
    }));
  };

  const handleGroupItems = (item) => {
    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName
          ? system
          : {
            ...system,
            equipments: equipmentList.map((el) =>
              el !== item ? el : { ...item, grouped: !item.grouped }
            ),
          }
      )
    );
  };

  const handleDelete = (item) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('item'),
    })
      .then(() => onRemoveItem(item))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleChangePrice = (event, equipmentID, itemID) => {
    setSystems((prevState) =>
      prevState.map((system) => system.name !== systemName ? system : {
        ...system,
        equipments: equipmentList.map((el) => el.equipmentId !== equipmentID ? el : {
          ...el,
          flexibleItems: el.flexibleItems.map((item) =>
            item.itemId !== itemID ? item: { ...item, [event.target.name]: event.target.value }
          ),
        }),
      })
    );
  };

  const handleChangeDescription = (event, equipmentID, itemID) => {
    setSystems((prevState) =>
      prevState.map((system) => system.name !== systemName
        ? system
        : {
          ...system,
          equipments: equipmentList.map((el) => el.equipmentId !== equipmentID ? el : {
            ...el,
            flexibleItems: el.flexibleItems.map((item) =>
              item.itemId !== itemID ? item : {
                ...item,
                item: { ...item.item, [event.target.name]: event.target.value },
              },
            ),
          })
        }
      )
    );
  };

  // Async functions
  const getEquipmentFilters = async () => {
    try {
      const response = await equipmentServices.getEquipmentKit();

      if (response.status === 200) setFiltersData(response.data);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const getItem = async (type, make, model, size, equipmentId, itemId) => {
    try {
      const response = await equipmentServices.find(type, make, model, size);

      if (response.status === 206) {
        const item = response.data.data[0];

        onItemReplacement(item, equipmentId, itemId);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getEquipmentFilters();
    if (!data) return;

    const listOfEquipment = data.map((el, equipmentId) => ({
      ...el,
      equipmentId,
      grouped: !!(el.grouped || el.flexibleItems.length > 1),
      flexibleItems: el.flexibleItems.map((item, itemId) => ({ ...item, itemId })),
    }));

    setEquipmentList(listOfEquipment);
  }, [data]);

  //

  if (!filtersData) return <Spinner />;

  return (
    <Box>
      <Typography variant="body1">
        <strong>Equipment</strong>
      </Typography>

      <SortContainer
        useDragHandle
        onSortEnd={({oldIndex, newIndex}) => {
          system.equipments = arrayMoveImmutable(equipmentList, oldIndex, newIndex);
          setEquipmentList(system.equipments);
        }}
      >
        {equipmentList.map((el, index) => (
          <SortableItem key={el.equipmentId} index={index}>
            <Box sx={{ mt: 2 }} className="item">
              <Grid container columnSpacing={1}>
                {equipmentList.length > 1 && <Grid item sx={{ maxWidth: 42, pt: '8px !important', pl: '0 !important' }}>
                  <DragHandle />
                </Grid>}
                <Grid item xs={11} sx={{ ...(el.grouped && proposal_grouped_items)}}>
                  <RadioGroup value={el.selectedItemIndex}>
                    <SortContainer
                      useDragHandle
                      onSortEnd={({oldIndex, newIndex}) => {
                        system.equipments = equipmentList.map(equipment => {
                          if (equipment !== el) return equipment;
                          const selectedItem = equipment.flexibleItems[el.selectedItemIndex];
                          const flexibleItems = arrayMoveImmutable(equipment.flexibleItems, oldIndex, newIndex);
                          return {
                            ...equipment,
                            selectedItemIndex: flexibleItems.indexOf(selectedItem),
                            flexibleItems
                          };
                        });
                        setEquipmentList(system.equipments);
                      }}
                    >
                      {el.flexibleItems?.map((item, index) => (
                        <SortableItem key={item.itemId} index={index}>
                          <Box
                            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                            className="item"
                          >
                            <Grid container columnSpacing={1} rowSpacing={2} sx={{ mb: 2 }}>
                              {el.grouped && el.flexibleItems.length > 1 && (
                                <Grid item sx={{ maxWidth: 42, pt: '24px !important' }}>
                                  <DragHandle />
                                </Grid>
                              )}
                              {el.grouped && el.flexibleItems.length > 1 && (
                                <Grid item sx={{ maxWidth: 42, pl: '0 !important' }}>
                                  <Radio
                                    name="selectedItemIndex"
                                    value={index}
                                    checked={index === el.selectedItemIndex}
                                    onChange={(event) => handleChange(event, el.equipmentId, item.itemId)}
                                  />
                                </Grid>
                              )}
                              {/* TYPE */}
                              <Grid item xs={12} md={2}>
                                <FormControl fullWidth>
                                  <InputLabel id="equipment_type">Type</InputLabel>
                                  <Select
                                    labelId="equipment_type"
                                    name="type"
                                    value={item?.item?.type || 'other'}
                                    label="Type"
                                    onChange={(event) => handleChange(event, el.equipmentId, item.itemId)}
                                    size="small"
                                  >
                                    {Object.keys(filtersData).map((el, index) => (
                                      <MenuItem key={index} value={el}>
                                        {el}
                                      </MenuItem>
                                    ))}
                                    <MenuItem value="other">Other</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>

                              {/* DESCRIPTION FOR OTHER */}
                              {item?.item?.type === 'other' && (
                                <Grid item xs={12} md={6}>
                                  <FormControl fullWidth size="small">
                                    <InputLabel required htmlFor="equipment_description">
                                      Description
                                    </InputLabel>
                                    <OutlinedInput
                                      required
                                      id="equipment_description"
                                      name="description"
                                      value={item?.item.description}
                                      label="Description"
                                      onChange={(event) => handleChangeDescription(event, el.equipmentId, item.itemId)}
                                    />
                                  </FormControl>
                                </Grid>
                              )}

                              {/* MAKE */}
                              {item?.item?.type !== 'other' && (
                                <Grid item xs={12} md={2}>
                                  <FormControl fullWidth>
                                    <InputLabel id="equipment_make">Make</InputLabel>
                                    {item.item.type && (
                                      <Select
                                        labelId="equipment_make"
                                        name="make"
                                        value={item?.item.make || '1'}
                                        label="Make"
                                        onChange={(event) => handleChange(event, el.equipmentId, item.itemId)}
                                        size="small"
                                        // disabled
                                      >
                                        <MenuItem value="1" sx={{ display: 'none' }}>
                                          Choose Make
                                        </MenuItem>
                                        {Object.keys(filtersData[item.item.type] || {}).map((el, index) => (
                                          <MenuItem key={index} value={el}>
                                            {el}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    )}
                                  </FormControl>
                                </Grid>
                              )}

                              {/* MODEL */}
                              {item?.item?.type !== 'other' && (
                                <Grid item xs={12} md={2}>
                                  <FormControl fullWidth>
                                    <InputLabel id="equipment_model">Model</InputLabel>
                                    <Select
                                      labelId="equipment_model"
                                      name="model"
                                      value={item.item.model || '1'}
                                      label="Model"
                                      onChange={(event) => handleChange(event, el.equipmentId, item?.itemId)}
                                      size="small"
                                      disabled={!item?.item.make}
                                    >
                                      <MenuItem value="1" sx={{ display: 'none' }}>
                                        Choose Model
                                      </MenuItem>
                                      {item.item.make &&
                                        Object.keys(filtersData[item.item.type]?.[item.item.make] || {}).map((el, index) => (
                                          <MenuItem key={index} value={el}>
                                            {el}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              )}

                              {/* SIZE */}
                              {item?.item?.type !== 'other' && (
                                <Grid item xs={12} md={2}>
                                  <FormControl fullWidth>
                                    <InputLabel id="equipment_size">Size</InputLabel>
                                    <Select
                                      labelId="equipment_size"
                                      name="size"
                                      value={item?.item.size || '1'}
                                      label="Size"
                                      onChange={(event) =>
                                        getItem(
                                          item.item.type,
                                          item.item.make,
                                          item.item.model,
                                          event.target.value,
                                          el.equipmentId,
                                          item.itemId,
                                        )
                                      }
                                      size="small"
                                      disabled={!item.item.model}
                                    >
                                      <MenuItem value="1" sx={{ display: 'none' }}>
                                        Choose Size
                                      </MenuItem>
                                      {item?.item.model &&
                                        Object.keys(filtersData[item.item.type]?.[item.item.make]?.[item.item.model] || {}).map(
                                          (el, index) => (
                                            <MenuItem key={index} value={el}>
                                              {el}
                                            </MenuItem>
                                          ),
                                        )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              )}

                              {/* PRICE */}
                              {item?.item?.type !== 'other' && (
                                <Grid item xs={12} md={2}>
                                  <FormControl fullWidth size="small">
                                    <InputLabel required htmlFor="equipment_price">
                                      Price
                                    </InputLabel>
                                    <OutlinedInput
                                      required
                                      id="equipment_price"
                                      name="price"
                                      value={
                                        item?.item.size
                                          ? item.price !== null
                                            ? item.price
                                            : filtersData[item.item.type][item.item.make][item?.item.model][item.item.size].price ||
                                              0
                                          : ''
                                      }
                                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                      label="Price"
                                      disabled={!item.item.size}
                                      onChange={(event) => handleChangePrice(event, el.equipmentId, item.itemId)}
                                    />
                                  </FormControl>
                                </Grid>
                              )}

                              {/* PRICE FOR OTHER */}
                              {item?.item?.type === 'other' && (
                                <Grid item xs={12} md={2}>
                                  <FormControl fullWidth size="small">
                                    <InputLabel required htmlFor="equipment_price">
                                      Price
                                    </InputLabel>
                                    <OutlinedInput
                                      required
                                      id="equipment_price"
                                      name="price"
                                      value={item.price || ''}
                                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                      label="Price"
                                      onChange={(event) => handleChangePrice(event, el.equipmentId, item.itemId)}
                                    />
                                  </FormControl>
                                </Grid>
                              )}

                              {/* BUTTONS */}
                              <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                {el.flexibleItems.length < 2 && (
                                  <Tooltip title={el.grouped ? 'Ungroup' : 'Create a group'}>
                                    <IconButton aria-label="group" onClick={() => handleGroupItems(el)}>
                                      {el.grouped ? <ClearIcon /> : <FormatListBulletedIcon />}
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Delete Item">
                                  <IconButton aria-label="remove" onClick={() => handleDelete(item)}>
                                    <DeleteForeverIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Box>
                        </SortableItem>
                      ))}
                    </SortContainer>
                  </RadioGroup>

                  {el.grouped && (
                    <FormControl sx={{ pb: 2 }}>
                      <InputLabel id="equipment">Add New</InputLabel>
                      <Select
                        labelId="equipment"
                        name="type"
                        value="1"
                        label="Add New"
                        onChange={(event) => onAddNewItemToGroup(event, el.equipmentId)}
                        size="small"
                      >
                        <MenuItem value="1">Select an item from the list to add</MenuItem>
                        {Object.keys(filtersData).map((el, index) => (
                          <MenuItem key={index} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Box>
          </SortableItem>
        ))}
      </SortContainer>

      <FormControl sx={{ mt: 2 }}>
        <InputLabel id="warranties">Add New</InputLabel>
        <Select labelId="warranties" name="type" value="1" label="Add New" onChange={onAddNewItem} size="small">
          <MenuItem value="1">Select an item from the list to add</MenuItem>
          {Object.keys(filtersData).map((el, index) => (
            <MenuItem key={index} value={el}>
              {el}
            </MenuItem>
          ))}

          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default EquipmentModule;
