import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { EditorProvider, Editor, Toolbar, BtnBold, BtnNumberedList, BtnBulletList } from 'react-simple-wysiwyg';

import {
  Box,
  Button,
  // Checkbox,
  // FormControlLabel,
  Typography,
  TextField,
  // FormControl,
  // FormLabel,
  Modal,
  FormHelperText,
} from '@mui/material';

// Utils
import messages from '../../static/messages';
import { AuthContext } from '../../context/AuthProvider';

// Services
import proposalsServices from '../../services/proposalsServices';
import clientsServices from '../../services/clientsServices';

// Styles
const modal_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: '#fff',
  boxShadow: 24,
  p: 4,
};

// External variables
const email_template = (id, data, token, name, title) => {
  const isStaging = window.location.hostname === 'localhost' || window.location.hostname === 'www.staging.airmaxxpro.com';
  const clientName = data?.property.clients[0]?.client.fullName;
  return ({
    subject: 'Estimate from Airmaxx',
    content: `
      <p>Hi${clientName ? ` ${clientName}` : ''},</p>

      <p>Please follow the link below to view your proposal:</p>

      <a href="https://www.${isStaging ? 'staging' : 'app'}.airmaxxpro.com/proposals/view/${id}?token=${token}">Your Proposal</a>

      <p>
        We have carefully outlined the recommended solutions to ensure optimal efficiency, reliability, and comfort. If
        you have any questions or would like to discuss the details further, feel free to reach out. We’re happy to
        assist you and tailor our services to best suit your needs.
      </p>

      <p>Best regards,<br />${name}${title ? `<br />${title}` : ''}</p>

      <table
        cellpadding="0"
        cellspacing="0"
        style="width: 420px; vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial; margin-top: 30px;"
      >
        <tbody>
          <tr>
            <td
              color="#0d3b59"
              direction="horizontal"
              width="auto"
              height="1"
              style="width: 100%; border-bottom: 1px solid rgb(13, 59, 89); border-left: none; display: block"
            ></td>
          </tr>
          <tr>
            <td>
              <table
                cellpadding="0"
                cellspacing="0"
                style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial"
              >
                <tbody>
                  <tr height="25" style="vertical-align: middle">
                    <td width="30" style="vertical-align: middle">
                      <table
                        cellpadding="0"
                        cellspacing="0"
                        style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial"
                      >
                        <tbody>
                          <tr>
                            <td style="vertical-align: bottom">
                              <span
                                color="#0d3b59"
                                width="11"
                                style="display: inline-block; background-color: rgb(13, 59, 89)"
                                ><img
                                  src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/phone-icon-2x.png"
                                  color="#0d3b59"
                                  alt="mobilePhone"
                                  width="13"
                                  class="contact-info__ContactLabelIcon-sc-mmkjr6-0 dGVIJx"
                                  style="display: block; background-color: rgb(13, 59, 89)"
                              /></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style="padding: 0px; color: rgb(0, 0, 0); vertical-align: bottom">
                      <a
                        href="tel:+16196553010"
                        color="#000000"
                        style="text-decoration: none; color: rgb(0, 0, 0); font-size: 12px"
                        ><span>(619) 655-3010</span></a
                      >
                    </td>
                    <td style="padding: 0px; text-align: right; width: 180px !important" rowspan="3">
                      <span style="display: inline-block; text-align: right; width: 100%"
                          ><img
                            src="https://airmaxx.com/wp-content/uploads/2025/03/airmaxx-logo.png"
                            role="presentation"
                            width="130"
                            height="47"
                            class="image__StyledImage-sc-hupvqm-0 fQKUvi"
                            style="display: inline; text-align: right; max-width: 130px"
                        /></span>
                    </td>
                  </tr>
                  <tr height="25" style="vertical-align: middle">
                    <td width="30" style="vertical-align: middle">
                      <table
                        cellpadding="0"
                        cellspacing="0"
                        style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial"
                      >
                        <tbody>
                          <tr>
                            <td style="vertical-align: bottom">
                              <span
                                color="#0d3b59"
                                width="11"
                                style="display: inline-block; background-color: rgb(13, 59, 89)"
                                ><img
                                  src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/email-icon-2x.png"
                                  color="#0d3b59"
                                  alt="emailAddress"
                                  width="13"
                                  class="contact-info__ContactLabelIcon-sc-mmkjr6-0 dGVIJx"
                                  style="display: block; background-color: rgb(13, 59, 89)"
                              /></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style="padding: 0px; vertical-align: bottom">
                      <a
                        href="mailto:airmaxx@airmaxx.com"
                        color="#000000"
                        style="text-decoration: none; color: rgb(0, 0, 0); font-size: 12px"
                        ><span>airmaxx@airmaxx.com</span></a
                      >
                    </td>
                  </tr>
                  <tr height="25" style="vertical-align: middle">
                    <td width="30" style="vertical-align: middle">
                      <table
                        cellpadding="0"
                        cellspacing="0"
                        style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial"
                      >
                        <tbody>
                          <tr>
                            <td style="vertical-align: bottom">
                              <span
                                color="#0d3b59"
                                width="11"
                                style="display: inline-block; background-color: rgb(13, 59, 89)"
                                ><img
                                  src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/link-icon-2x.png"
                                  color="#0d3b59"
                                  alt="website"
                                  width="13"
                                  style="display: block; background-color: rgb(13, 59, 89)"
                              /></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style="padding: 0px; vertical-align: bottom">
                      <a
                        href="https://airmaxx.com"
                        target="_blank"
                        color="#000000"
                        style="text-decoration: none; color: rgb(0, 0, 0); font-size: 12px"
                        ><span>airmaxx.com</span></a
                      >
                    </td>
                  </tr>
                  <tr height="25" style="vertical-align: middle">
                    <td width="30" style="vertical-align: middle">
                      <table
                        cellpadding="0"
                        cellspacing="0"
                        style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial"
                      >
                        <tbody>
                          <tr>
                            <td style="vertical-align: bottom">
                              <span
                                color="#0d3b59"
                                width="11"
                                style="display: inline-block; background-color: rgb(13, 59, 89)"
                                ><img
                                  src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/address-icon-2x.png"
                                  color="#0d3b59"
                                  alt="address"
                                  width="13"
                                  style="display: block; background-color: rgb(13, 59, 89)"
                              /></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style="padding: 0px; vertical-align: bottom" colspan="2">
                      <span color="#000000" style="font-size: 12px; color: rgb(0, 0, 0)"
                        ><span>1865 John Towers Ave, #B, El Cajon, CA 92020</span></span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              color="#0d3b59"
              direction="horizontal"
              width="auto"
              height="1"
              style="width: 100%; border-bottom: 1px solid rgb(13, 59, 89); border-left: none; display: block; height: 20px !important"
            ></td>
          </tr>
        </tbody>
      </table>
    `,
  })
};

const required_fields = ['emailTo', 'subject', 'content'];

const EmailProposalModal = ({ isOpen, onClose, data }) => {
  const { id } = useParams();
  const { userData } = useContext(AuthContext);

  const [email, setEmail] = useState();
  const [errors, setErrors] = useState({});
  const [isReady, setReady] = useState(false);

  // Action functions
  const resetErrors = () => {
    setErrors({});
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  // Handler functions
  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    setEmail((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const onChangeTemplate = (event) => setEmail((prevState) => ({ ...prevState, content: event.target.value }));

  const handleValidate = () => {
    resetErrors();

    required_fields.forEach((field) => {
      if (email[field] === '' || !email[field]) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
        setReady(false);
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
        setReady(true);
      }
    });
  };

  // Async functions
  const getClientToken = async () => {
    const clientId = data?.property?.clients[0]?.client.id;

    try {
      const response = await clientsServices.getClientToken(clientId);

      if (response.status === 200) {
        const clientsList = data?.property?.clients;
        const senList = data?.emailTo;
        let emailsList = [];

        clientsList?.forEach((client) => {
          senList?.forEach((id) => {
            if (client.client.id === id) emailsList.push(client.client.email);
          });
        });

        setEmail({
          ...email_template(id, data, response.data.token, userData.fullName, userData.title),
          emailTo: emailsList.join(', '),
        });
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const sendEmail = async () => {
    const emailData = {
      ...email,
      emailTo: email.emailTo.split(',').map((el) => el.trim()),
    };

    try {
      const response = await proposalsServices.sendEmail(id, emailData);

      if (response.status === 200) {
        setEmail(email_template(id, data));
        onClose();
        toast.success('Email sent successfully');
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getClientToken();

    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  }, [data]);

  useEffect(() => {
    if (isReady) !Object.values(errors).includes(true) && sendEmail();
  }, [errors]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modal_style}>
        <Typography variant="h5" sx={{ textAlign: 'center' }}>
          Email this proposal
        </Typography>

        <TextField
          required
          name="emailTo"
          label="To"
          value={email?.emailTo || ''}
          fullWidth
          onChange={handleChange}
          error={errors.emailTo}
          helperText={errors.emailTo && messages.errors.required_field}
          sx={{ mt: 4 }}
        />

        <TextField
          required
          name="subject"
          label="Subject"
          value={email?.subject}
          fullWidth
          autoFocus
          onChange={handleChange}
          error={errors.subject}
          helperText={errors.subject && messages.errors.required_field}
          sx={{ mt: 4, mb: 4 }}
        />

        <EditorProvider>
          <Editor value={email?.content} onChange={onChangeTemplate} style={{ border: '1px solid red !important' }}>
            <Toolbar>
              <BtnBold />
              <BtnBulletList />
              <BtnNumberedList />
            </Toolbar>
          </Editor>
        </EditorProvider>
        {errors.content && <FormHelperText error>{messages.errors.required_field}</FormHelperText>}

        <Box sx={{ mt: 4, float: 'right' }}>
          <Button variant="contained" color="error" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="success" sx={{ ml: 1 }} onClick={handleValidate}>
            Send
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EmailProposalModal;
