import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// Icons
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// Custom components
import { Spinner } from '../../../';

// Utils
import messages from '../../../../static/messages';

// Services

// Styles
import { proposal_grouped_items } from '../../../../static/styles';

// Dragging

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => <DragIndicatorIcon sx={{ cursor: 'grab' }} />); 
const SortableItem = SortableElement(({children}) => <div>{children}</div>);
const SortContainer = SortableContainer(({children}) => <div>{children}</div>);

// External variables
const base_filters = [`Qualifies for "Carrier" rebate`, 'Qualifies for EUC rebate', 'Qualifies for Federal Tax Credit'];

const RebatesModule = ({ systemName, data, setSystems, system }) => {
  const [rebates, setRebates] = useState([]);
  const [filtersData, setFiltersData] = useState([]);

  const confirm = useConfirm();

  // Action functions
  const onAddNewItem = (event) => {
    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          rebates: system.rebates.concat({
            flexibleItems: [
              {
                item: event.target.value,
                price: null,
              },
            ],
            selectedItemIndex: 0,
          }),
        }
      )
    );
  };

  const onAddNewItemToGroup = (event, rebateID) => {
    const newItem = event.target.value;

    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          rebates: rebates.map((el) =>
            el.rebateId !== rebateID ? el : {
              ...el,
              flexibleItems: el.flexibleItems.concat({
                item: newItem,
                price: null,
                itemId: el.flexibleItems.length,
              }),
            }
          ),
        }
      )
    );
  };

  const onRemoveItem = (item) => {
    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          rebates: rebates
            .map((el) =>
              !el.flexibleItems.includes(item) ? el : {
                ...el,
                flexibleItems: el.flexibleItems.filter((i) => i !== item),
              }
            )
            .filter((el) => el.flexibleItems.length),
        }
      )
    );
  };

  // Handler functions
  const handleChange = (event, rebateID, itemID) => {
    const key = event.target?.name || 'expires';
    const val = event.target?.value || moment(new Date(`${event.$M + 1}/${event.$D}/${event.$y}`)).format();

    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          rebates: rebates.map((el) =>
            el.rebateId !== rebateID ? el : {
              ...el,
              ...(key === 'selectedItemIndex'
                ? { selectedItemIndex: Number(val) }
                : {
                  flexibleItems: el.flexibleItems.map((item) => {
                    if (item.itemId !== itemID) return item;
                    if (key === 'price') return { ...item, price: Number(val) || 0 };
                    if (key === 'expires') return { ...item, item: { ...item.item, expires: val } };
                    if (key === 'description') return { ...item, item: { ...item.item, description: val } };
                    return { ...item, [key]: val };
                  }),
                }
              ),
            }
          ),
        }
      )
    );
  };

  const handleDelete = (item) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('item'),
    })
      .then(() => onRemoveItem(item))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleGroupItems = (item) => {
    setSystems((prevState) =>
      prevState.map((system) =>
        system.name !== systemName ? system : {
          ...system,
          rebates: rebates.map((el) => el !== item ? el : { ...el, grouped: !el.grouped }),
        }
      )
    );
  };

  // Async functions

  // Hooks
  useEffect(() => {
    if (base_filters) {
      const unique_values = base_filters.reduce(
        (acc, el) => {
          if (acc.map[el]) return acc;
          acc.map[el] = true;
          acc.rebates.push({
            description: el,
            price: 0,
            expires: moment(new Date(new Date().getFullYear(), 11, 31)).format(),
          });
          return acc;
        },
        { map: {}, rebates: [] }
      );
      setFiltersData(unique_values.rebates);
    }

    if (data) {
      const listOfRebates = data.map((el, rebateId) => ({
        ...el,
        rebateId,
        grouped: !!(el.flexibleItems.length > 1 || el.grouped),
        flexibleItems: el.flexibleItems.map((item, itemId) => ({
          ...item,
          itemId,
        })),
      }));

      setRebates(listOfRebates);
    }
  }, [data]);

  if (!filtersData) return <Spinner />;

  return (
    <Box>
      <Typography variant="body1">
        <strong>Rebates</strong>
      </Typography>

      <SortContainer
        useDragHandle
        onSortEnd={({oldIndex, newIndex}) => {
          system.rebates = arrayMoveImmutable(rebates, oldIndex, newIndex);
          setRebates(system.rebates);
        }}
      >
        {rebates.map((el, index) => (
          <SortableItem key={el.rebateId} index={index}>
            <Box sx={{ mt: 2 }}>
              <Grid container columnSpacing={1}>
                {rebates.length > 1 && (
                  <Grid item sx={{ maxWidth: 42, pt: '8px !important', pl: '0 !important' }}>
                    <DragHandle />
                  </Grid>
                )}
                <Grid item xs={11} sx={{ ...(el.grouped && proposal_grouped_items)}}>
                  <RadioGroup value={el.selectedItemIndex}>
                    <SortContainer
                      useDragHandle
                      onSortEnd={({oldIndex, newIndex}) => {
                        system.rebates = rebates.map(rebate => {
                          if (rebate !== el) return rebate;
                          const selectedItem = rebate.flexibleItems[el.selectedItemIndex];
                          const flexibleItems = arrayMoveImmutable(rebate.flexibleItems, oldIndex, newIndex);
                          return {
                            ...rebate,
                            selectedItemIndex: flexibleItems.indexOf(selectedItem),
                            flexibleItems
                          };
                        });
                        setRebates(system.rebates);
                      }}
                    >
                      {el.flexibleItems?.map((item, index) => (
                        <SortableItem key={item.itemId} index={index}>
                          <Box
                            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                            className="item"
                          >
                            <Grid container columnSpacing={1} rowSpacing={2} sx={{ mb: 2 }}>
                              {el.grouped && el.flexibleItems.length > 1 && (
                                <Grid item sx={{ maxWidth: 42, pt: '24px !important' }}>
                                  <DragHandle />
                                </Grid>
                              )}
                              {el.grouped && el.flexibleItems.length > 1 && (
                                <Grid item sx={{ maxWidth: 42, pl: '0 !important' }}>
                                  <Radio
                                    name="selectedItemIndex"
                                    value={index}
                                    checked={index === el.selectedItemIndex}
                                    onChange={(event) => handleChange(event, el.rebateId, item.itemId)}
                                  />
                                </Grid>
                              )}
                              <Grid item xs={12} md={6}>
                                <TextField
                                  name="description"
                                  label="Description"
                                  value={item.item.description}
                                  fullWidth
                                  size="small"
                                  onChange={event => handleChange(event, el.rebateId, item.itemId)}
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <FormControl fullWidth size="small">
                                  <InputLabel required htmlFor="price">
                                    Price
                                  </InputLabel>
                                  <OutlinedInput
                                    required
                                    id="price"
                                    name="price"
                                    value={item.price !== null ? item.price || '' : item.item.price}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Price"
                                    onChange={(event) => handleChange(event, el.rebateId, item.itemId)}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={1}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <MobileDatePicker
                                    label="Expires"
                                    inputFormat="MM/DD/YYYY"
                                    value={moment(item.item.expires).format('MM/DD/YYYY')}
                                    onChange={(date) => handleChange(date, el.rebateId, item.itemId)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                {el.flexibleItems.length < 2 && (
                                  <Tooltip title={el.grouped ? 'Ungroup' : 'Create a group'}>
                                    <IconButton aria-label="group" onClick={() => handleGroupItems(el)}>
                                      {el.grouped ? <ClearIcon /> : <FormatListBulletedIcon />}
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Delete Item">
                                  <IconButton aria-label="remove" onClick={() => handleDelete(item)}>
                                    <DeleteForeverIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Box>
                        </SortableItem>
                      ))}
                    </SortContainer>
                  </RadioGroup>
                  {el.grouped && (
                    <FormControl sx={{ pb: 2 }}>
                      <InputLabel id="rebates">Add New</InputLabel>
                      <Select
                        labelId="rebates"
                        name="item"
                        value="1"
                        label="Add New"
                        onChange={(event) => onAddNewItemToGroup(event, el.rebateId)}
                        size="small"
                      >
                        <MenuItem value="1">Select an item from the list to add</MenuItem>
                        {filtersData?.map((el, index) => (
                          <MenuItem key={index} value={el}>
                            {el.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Box>
          </SortableItem>
        ))}
      </SortContainer>

      <FormControl sx={{ mt: 2 }}>
        <InputLabel id="rebates">Add New</InputLabel>
        <Select
          labelId="rebates"
          name="item"
          value="1"
          label="Add New"
          onChange={(event) => onAddNewItem(event)}
          size="small"
        >
          <MenuItem value="1">Select an item from the list to add</MenuItem>
          {filtersData?.map((el, index) => (
            <MenuItem key={index} value={el}>
              {el.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RebatesModule;
